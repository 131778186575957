import { TableCellProps, Box } from "@material-ui/core";
import { ObjetoAcesso, RetornoAcesso } from "../Modelos/ObjetosDiversos";
import { Funcoes_Recursos } from "../Modelos/FuncoesRecursos";
import { Dados_Adicionais } from "../Modelos/Dados_Adicionais";
import React from "react";
import { Valores_Grupos } from "../Modelos/Valores_Grupos";
import { Parametros } from "../Modelos/Parametros";
import { Recebimentos } from "../Modelos/Recebimentos";
import { Contas } from "../Modelos/Contas";
import { Feriados } from "../Modelos/Feriados";
import { Font } from "@react-pdf/renderer";
import { Tabelas_Dados_Adicionais } from "../Modelos/Tabelas_Dados_Adicionais";
import { Pessoa_Documentos } from "../Modelos/Pessoa_Documentos";
import { Documentos } from "../Modelos/Documentos";
import { Tipos_Grupos } from "../Modelos/Tipos_Grupos";
import { Clientes_Valores_Grupos } from "../Modelos/Clientes_Valores_Grupos";
import { Valores_Dados_Adicionais } from "../Modelos/Valores_Dados_Adicionais";
import CryptoJS from 'crypto-js';
import { Dashboard_Operador } from "../Modelos/Dashboard_Operador";
import Moment from 'moment';

export const urlbase: string = 'http://rcky.masteroffice.inf.br';
//export const urlbase: string = 'http://somei.masteroffice.inf.br';

export function trocaTodos(strbase: string, retira:string[], coloca: string[])
{
  let retorno: string=strbase;
  for (let pos:number=0;pos<retira.length && pos<coloca.length;pos=pos+1)
  {
    retorno=retorno.split(retira[pos]).join(coloca[pos]);
  }
  return retorno
}

export function nulltoNumber(vlr: number | undefined): number
{
  let ret: number=0;
  if (vlr && !isNaN(vlr))
  {
    ret=vlr;
  }
  return ret;
}

export function tamanhoExato(strajusta: string, tamanho: number, preenche: string=' ', inicio: boolean=true): string
{
  return inicio ? strajusta.trim().padStart(tamanho, preenche).substr(0,tamanho) : strajusta.trim().padEnd(tamanho, preenche).substr(0,tamanho);
}

export function hex_to_ascii(str1: string): string
{
	var hex  = str1.toString();
	var str = '';
	for (var n = 0; n < hex.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;
}

export function arrayBufferToBase64( buffer:ArrayBuffer ): any {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

 export function base64ToArrayBuffer(base64: string) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

export function VerificaCpfCnpj(cpfcnpj: string): boolean
{
    if (!/^\d+$/.test(cpfcnpj))
    {
        return false;
    }
    if (cpfcnpj.length !== 11 && cpfcnpj.length !==14)
    {
        return false;
    }
    return cpfcnpj.length===11 ? VerificaCpf(cpfcnpj) : VerificaCnpj(cpfcnpj);
}

export function linhadigitavel(banco: string, agencia: string, conta: string, carteira: string, numban: string, vencto: Date, valor: number): string
{
  let linret: string = '';
  let linha: string = '';
  let campo: string = '';
  
  switch (banco.trim())
  {
    case '237':
    {
      linha=tamanhoExato(banco,3,'0',true) + '9' + tamanhoExato(DifDias(new Date(1997,9,7,0,0,0,0),vencto,false,true).toString(),4,'0',true) + tamanhoExato((valor*100).toFixed(0).toString(),10,'0',true) + tamanhoExato(agencia,4,'0',true) + tamanhoExato(carteira,2,'0',true)+ tamanhoExato(numban,11,'0',true)+tamanhoExato(conta,7,'0',true)+'0';
      let digbar: string = Modulo11(linha);
      linha=linha.substr(0,4)+digbar+linha.substr(4,39);
      campo=linha.substr(0,4)+linha.substr(19,5);
      linret=campo+Modulo10(campo);
      campo=linha.substr(24,10);
      linret=linret+campo+Modulo10(campo);
      campo=linha.substr(34,10);
      linret=linret+campo+Modulo10(campo) + digbar + linha.substr(5,14);
      break
    }
  }

  return linret;
}

export function Modulo10(base: string): string
{
  let digito: string='0';
  let indmult: number = 1;
  let soma: number = 0;
  let resdig: string='';
  for (let nind:number=base.length-1; nind>=0; nind=nind-1)
  {
    indmult=(indmult===1 ? 2 : 1);
    resdig=(nulltoNumber(parseInt(base.substr(nind,1)))*indmult).toString().trim();
    for (let ndig: number=0; ndig<resdig.length; ndig=ndig+1)
    {
      soma=soma+nulltoNumber(parseInt(resdig.substr(ndig,1)));
    }
  }
  if ((soma % 10)>0)
  {
    digito = (10 - (soma % 10)).toString();
  }

  return digito;
}

export function Modulo11(base: string): string
{
  let digito: string='1';
  const mult: number[] = [2,3,4,5,6,7,8,9];
  let indmult: number = 0
  let soma: number = 0;
  for (let nind:number=base.length-1; nind>=0; nind=nind-1)
  {
    if (indmult>7)
    {
      indmult=0
    }
    soma=soma+(nulltoNumber(parseInt(base.substr(nind,1)))*mult[indmult])
    indmult=indmult+1;
  }
  let dignumber: number = 11 - (soma % 11);
  if (dignumber>1 && dignumber<10)
  {
    digito=dignumber.toString();
  }

  return digito;
}

export function VerificaCpf(cpf: string): boolean
{
    if (!/^\d+$/.test(cpf))
    {
        return false;
    }
    if (cpf.length !== 11)
    {
      return false;
    }
    let soma: number=0
    let cpfcalc: string = cpf.substr(0,9);
    for (let ind=0; ind<9; ind++)
    {
        soma=soma+(parseInt(cpfcalc.substr(ind,1))*(10-ind))
    }
    let digito: number = 11 - (soma % 11);
    cpfcalc = cpfcalc + (digito>9 ? "0" : digito.toString());
    soma=0;
    for (let ind=0; ind<10; ind++)
    {
        soma=soma+(parseInt(cpfcalc.substr(ind,1))*(11-ind))
    }
    digito = 11 - (soma % 11);
    cpfcalc = cpfcalc + (digito>9 ? "0" : digito.toString());
    return cpf.substr(9,2)===cpfcalc.substr(9,2);
}

export function VerificaCnpj(cnpj: string): boolean
{
    if (!/^\d+$/.test(cnpj))
    {
        return false;
    }
    if (cnpj.length !== 14)
    {
        return false;
    }
    let soma: number=0
    let cnpjcalc: string = cnpj.substr(0,12);
    for (let ind=0; ind<12; ind++)
    {
        soma=soma+(parseInt(cnpjcalc.substr(ind,1))*((ind<4 ? 5 : 13) -ind))
    }
    soma = soma * 10;
    let digito: number = soma % 11;
    cnpjcalc = cnpjcalc + (digito === 10 ? "0" : digito.toString());
    soma=0;
    for (let ind=0; ind<13; ind++)
    {
        soma=soma+(parseInt(cnpjcalc.substr(ind,1))*((ind<5 ? 6 : 14) -ind))
    }
    soma = soma * 10;
    digito = soma % 11;
    cnpjcalc = cnpjcalc + (digito === 10 ? "0" : digito.toString());
    return cnpj.substr(12,2)===cnpjcalc.substr(12,2);
}

export function AtualizaDocumentos(docs: Documentos[], docspessoa: Pessoa_Documentos[]): Documentos[]
{
    docs.forEach((docto: Documentos,index) => 
    {
        if (docto.id_doc>2)
        {
            let indcli=docspessoa.findIndex((docpes: Pessoa_Documentos) => docpes.id_doc===docto.id_doc);
            docs[index].valor_psdc=indcli>=0 ? docspessoa[indcli].valor_psdc : '';
            docs[index].alterado=indcli>=0 ? 'P' : '';
        }
    });
    return docs;
}

export function AtualizaGrupos(tpgrupos: Tipos_Grupos[], valoresgr: Clientes_Valores_Grupos[]): Tipos_Grupos[]
{
  tpgrupos.forEach((tipogrupo: Tipos_Grupos,index) => 
  {
    let indcli=valoresgr.findIndex((valgrupo: Clientes_Valores_Grupos) => tipogrupo.valores.some(valor => valor.id_vlgr===valgrupo.id_vlgr));
    tpgrupos[index].id_vlgr=indcli>=0 ? valoresgr[indcli].id_vlgr : 0;
    tpgrupos[index].alterado= indcli>=0 ? 'C' : '';
  });
  return tpgrupos;
}

export function AtualizaDadosAdicionais(ddsadc: Dados_Adicionais[], tbddadc: Tabelas_Dados_Adicionais[], tpdado: string): Dados_Adicionais[]
{
    ddsadc.forEach((ddadc: Dados_Adicionais, index) =>
    {
        if (ddadc.comportamento_dadc===tpdado)
        {
            let valenc: {idvdad: number, numerotbad: number, textotbad: string, encontrado:string} = {idvdad: 0, numerotbad:0, textotbad:'', encontrado: '*'};
            let indcli=tbddadc.findIndex((dado: Tabelas_Dados_Adicionais) => dado.id_dadc===ddadc.id_dadc)
            if (indcli>=0)
            {
                valenc.idvdad=tbddadc[indcli].id_vdad;
                valenc.numerotbad=tbddadc[indcli].numero_tbad;
                valenc.textotbad=tbddadc[indcli].texto_tbad;
                valenc.encontrado=(tpdado==='B' ? 'P' : 'F');
            }
            ddsadc[index].alterado=valenc.encontrado;
            switch (ddadc.tipo_dadc)
            {
                case 'C':
                {
                    ddsadc[index].id_vdad=valenc.idvdad;
                    break;
                }
                case 'B':
                {
                    ddsadc[index].numero_tbad=valenc.numerotbad;
                    break;
                }
                case 'A':
                {
                    ddsadc[index].texto_tbad=valenc.textotbad;
                    break
                }
                default:
                {
                    break;
                }
            }
        }
    });
    return ddsadc;
}

export const RetornaValoresDadosAdicionais = (vlda: Valores_Dados_Adicionais[]): {id: number, descricao: string}[] =>
{
    let valdadc: {id: number, descricao: string}[]=[];
    vlda.forEach((vld:Valores_Dados_Adicionais) => valdadc.push({id: vld.id_vdad, descricao: vld.desc_vdad}));
    return valdadc;
}

export function formataDecimal(numdec:string): string
{
    let retorno: string = numdec;
    while (retorno.indexOf('.') !== -1)
    {
      retorno=retorno.replace('.','');
    }
    const posvirg:number = retorno.search(',');
    if(posvirg>=0 && retorno.length>posvirg+2)
    {
      retorno=retorno.slice(0,posvirg+3)
    }
    retorno=retorno.replace(',','.')
    return retorno;
}

export function ConverteStringData(dtstring: string): Date | undefined
{
  let retorno: Date | undefined = undefined;
  try 
  {
    if(dtstring.length>=19 && dtstring.substr(4,1)==='-' && dtstring.substr(7,1)==='-' && dtstring.substr(10,1)==='T' && dtstring.substr(13,1)===':' && dtstring.substr(16,1)===':')
    {
      retorno=new Date(parseInt(dtstring.substr(0,4)),parseInt(dtstring.substr(5,2))-1,parseInt(dtstring.substr(8,2)),parseInt(dtstring.substr(11,2)),parseInt(dtstring.substr(14,2)),parseInt(dtstring.substr(17,2)))
    }
    else if(dtstring.length>9 && dtstring.substr(4,1)==='-' && dtstring.substr(7,1)==='-')
    {
      retorno=new Date(parseInt(dtstring.substr(0,4)),parseInt(dtstring.substr(5,2))-1,parseInt(dtstring.substr(8,2)))
    }
  } 
  catch (error) 
  {
    retorno = undefined;
  }
  return retorno
}

export function RetornaStringData(data: any, rethora: boolean = false): string
{
  let retorno: string = '  /  /    ';
  if(data)
  {
    if(typeof data==='string')
    {
      data=ConverteStringData(data);
    }
    if(data && data.getDate!==undefined)
    {
      retorno=data.getDate().toString().padStart(2,'0')+'/'+(data.getMonth()+1).toString().padStart(2,'0')+'/'+data.getFullYear().toString().padStart(4,'0') + (rethora ? ' '+data.getHours().toString().padStart(2,0)+':'+data.getMinutes().toString().padStart(2,0)+':'+data.getSeconds().toString().padStart(2,0) : '');
    }
  }
  return retorno;
}

export function RetornaDataString(data: any): Date
{
  let retorno: Date = new Date();
  if(data)
  {
      if(data.getDate!==undefined)
      {
          retorno=data
      }
      else
      {
          if(typeof data==='string')
          {
            let retdata: Date | undefined = ConverteStringData(data);
            if(retdata)
            {
                retorno=retdata;
            }
          }
      }
  }
  return retorno;
}

export function RetornaDataAAAAMMDD(data: any, vazio: boolean=true): string
{
    if (data)
    {
      let dtdata=RetornaDataString(data);
      return dtdata.getFullYear().toString().padStart(4,'0')+(dtdata.getMonth()+1).toString().padStart(2,'0')+dtdata.getDate().toString().padStart(2,'0');
    }
    else
    {
      return vazio ? '' : ' ';
    }
}

export function RetornaDataDDMMAA(data: any): string
{
    if (data)
    {
      let dtdata=RetornaDataString(data);
      return dtdata.getDate().toString().padStart(2,'0')+(dtdata.getMonth()+1).toString().padStart(2,'0')+dtdata.getFullYear().toString().padStart(4,'0').substr(2,2);
    }
    else
    {
      return '000000';
    }
}

export function formatarCampo(campoTexto: string, formata: string, decimais:boolean=true): string 
{
  let retorno: string = '';
  if (campoTexto!==undefined)
  {
    switch(formata)
    {
      case 'cep':
      {
        retorno=campoTexto.substr(0,5)+'-'+campoTexto.substr(5,3);
        return retorno;
      }
      case 'cpfcnpj':
      {
        if(campoTexto.length === 11) 
        {
          retorno=mascaraCpf(campoTexto);
        } 
        else 
        {
          retorno=mascaraCnpj(campoTexto);
        }
        return retorno;
      }
      case 'decimal':
      {
        let retorno=parseFloat(campoTexto).toLocaleString('pr-BR', {style: 'decimal'});
        const posvirg=retorno.indexOf(',')
        if(posvirg===-1)
        {
          retorno=retorno+(decimais ? ',00' : '')
        }
        else if(posvirg===retorno.length-1)
        {
          retorno=(decimais ? retorno+'00' : retorno.substr(0,retorno.length-1));
        }
        else if(posvirg===retorno.length-2)
        {
          retorno= (decimais ? retorno+'0' : retorno.substr(0,retorno.length-2));
        }
        else if(posvirg<(retorno.length-3) && decimais)
        {
          retorno=retorno.substr(0,posvirg+3)
        }
        return retorno;
      }
      case 'datahora':
      {
        retorno=RetornaStringData(campoTexto,true);
        console.log('datahora: ',campoTexto, retorno);
        return retorno;
      }
      case 'data':
      {
        retorno=RetornaStringData(campoTexto,false);
        return retorno;
      }
      default:
      {
        retorno=campoTexto.toString();
        return retorno;
      }
    }
  }
  return retorno;
}
function mascaraCpf(valor: string) {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,'$1.$2.$3-$4');
}
function mascaraCnpj(valor: string) {
    return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"$1.$2.$3/$4-$5");
}

export function mascaraLinhadigitavel(valor: string) {
  return valor.replace(/(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{14})/g,"$1.$2 $3.$4 $5.$6 $7 $8");
}

export function ClicaBotao(nomebt?:string): void
{
  const btnultpag: HTMLElement | null = document.getElementById(nomebt ? nomebt : 'ultpagtab');
  if(btnultpag)
  {
    btnultpag.click();
  }
}

export function RetornaUrlPdfImg(doc: Uint32Array | undefined, tpdoc: string): string | undefined
{
  let url: string | undefined = undefined;
  if(doc)
  {
    let arrayBuffer = base64ToArrayBuffer(doc.toString());
    var blob = new Blob([arrayBuffer], { type: tpdoc==='A' ? 'image/jpeg' : 'application/pdf' });
    url = URL.createObjectURL(blob);
  }
  return url;
}

export interface Iheaders {
    titulo: string,
    alinhamento: TableCellProps['align'],
    coluna: string,
    mascara: string,
    dica?: string,
    calculo?: {basenum: number, colbase: string}
}

export function RetornaMascara(mascini: string, valini: string): string
{
  let mascfin=mascini;
  if(mascfin==='Telefone')
  {
    if(valini.substr(0,4)==='0800')
    {
      mascfin='#### ### ####'
    }
    else if(valini.length===12)
    {
      mascfin='+## (##) ####-#####'
    }
    else
    {
      mascfin='+## (##) #####-####'
    }
  };
  return mascfin;
}

export function ValidaEmail(email: string)
{
  if (!/^[a-z0-9._-]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i.test(email))
  {
      return false;
  }
  return true;
}

export function RetiraAcentos(texto: string): string
{
  const a = 'àáäâãèéëêìíïîòóöôõùúüûǘñçßÿœæŕśńṕẃǵǹḿẍźḧÀÁÄÂÃÈÉËÊÌÍÏÎÒÓÖÔÕÙÚÜÛÑÇ`´';
  const b = 'aaaaaeeeeiiiiooooouuuuuncsyoarsnpwgnmxzhAAAAAEEEEIIIIOOOOOUUUUNC\'\'';
  let rettexto=''
  for(let i=0;i<texto.length;i=i+1)
  {
    let achou=a.indexOf(texto.substr(i,1))
    rettexto = rettexto + (achou===-1 ? texto.substr(i,1) : b.substr(achou,1));
  }
  return Buffer.from(rettexto,'ascii').toString('ascii');
}

export type ObjPdfImg =
{
    tipo: string, 
    conteudo?: Uint32Array | undefined
}

export function AchaFuncoes(objacc: ObjetoAcesso, idfnrc: number)
{
  if (objacc.tipoacesso_perf==='A')
  {
    return true
  }
  else
  {
    return objacc.funcoes.some((funcao:Funcoes_Recursos) => funcao.id_fnrc===idfnrc)
  }
}

export function TrataResposta(res: any): Resposta
{
    let Retorno: Resposta = {ok: false, erro: '', retorno: undefined};
    Retorno.retorno=res
    if(res.erro)
    {
      Retorno.ok=false;
      Retorno.erro=res.erro
    }
    else
    {
      Retorno.ok=true;
      Retorno.erro = '';
    }
    return Retorno;
}

export interface Resposta {
    ok: boolean,
    erro: string,
    retorno: any
}

export interface BotaoDialogo {
  descricao: string,
  retorno: string
}

export function ValidaDadosAdicionais(dado: Dados_Adicionais):boolean
{
    let dadook:boolean=true;

    if(dado.opcional_dadc!=='A')
    {
        switch (dado.tipo_dadc)
        {
            case 'A':
            {
              if(!dado.texto_tbad || dado.texto_tbad.trim().length<1 || (dado.tamanho_dadc && dado.tamanho_dadc>0 && dado.texto_tbad.trim().length>dado.tamanho_dadc))
              {
                dadook=false;
              }
              else if(dado.mascara_dadc.toUpperCase()==='CPF')
              {
                dadook=VerificaCpf(dado.texto_tbad);
              }
              else if(dado.mascara_dadc.toUpperCase()==='CNPJ')
              {
                dadook=VerificaCnpj(dado.texto_tbad);
              }
              else if(dado.mascara_dadc.toUpperCase()==='CPFCNPJ')
              {
                dadook=VerificaCpfCnpj(dado.texto_tbad);
              }
              break;
            }
            case 'B':
            {
                if(!dado.numero_tbad || dado.numero_tbad<dado.valmin_dadc || dado.numero_tbad>dado.valmax_dadc)
                {
                    dadook=false;
                }
                break;
            }
            case 'C':
            {
              if(!dado.id_vdad || dado.id_vdad===0)
                {
                    dadook=false;
                }
                break;
            }
        }
    }
    else
    {
        switch (dado.tipo_dadc)
        {
            case 'A':
            {
                if(dado.texto_tbad && dado.texto_tbad.length>0)
                {
                  if(dado.tamanho_dadc && dado.tamanho_dadc>0 && dado.texto_tbad.trim().length>dado.tamanho_dadc)
                  {
                      dadook=false;
                  }
                  else if(dado.mascara_dadc.toUpperCase()==='CPF')
                  {
                    dadook=VerificaCpf(dado.texto_tbad);
                  }
                  else if(dado.mascara_dadc.toUpperCase()==='CNPJ')
                  {
                    dadook=VerificaCnpj(dado.texto_tbad);
                  }
                  else if(dado.mascara_dadc.toUpperCase()==='CPFCNPJ')
                  {
                    dadook=VerificaCpfCnpj(dado.texto_tbad);
                  }
                }
                break;
            }
            case 'B':
            {
                if(dado.numero_tbad && dado.numero_tbad!==0 && (dado.numero_tbad<dado.valmin_dadc || dado.numero_tbad>dado.valmax_dadc))
                {
                    dadook=false;
                }
                break;
            }
        }
    }
    return dadook;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  classe?: string;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, classe, ...other } = props;
  
  return (
    <div className={classe ? classe : undefined} style={classe ? undefined : {background: 'linear-gradient(to bottom,  #FFFFFF 0%, #E0F2FA 100%)', borderRadius: '7px', width: '100%', margin: '0% 0% 0% 0%', padding: '0 0 0 0'}}
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export interface datas {dtini?: Date | undefined, dtfin?: Date | undefined};

export function MatrizParaData(mt: string[]): Date | undefined
{
  let ret: Date | undefined;
  try {
    ret= new Date(parseInt(mt[0]),parseInt(mt[1]),parseInt(mt[2]))
    if (ret.toString().toLowerCase()==='invalid date')
    {
      ret=undefined;
    }
  } catch (error) {
    ret = undefined
  }
  return ret;
}

export function DataParaString(dt: Date | undefined): string
{
  let ret: string;
  try 
  {
    ret= dt?.getFullYear().toString().trim()+','+dt?.getMonth().toString().trim()+','+dt?.getDate().toString().trim()
  } 
  catch (error)
  {
    ret = '0,0,0';
  }
  return ret;
}

export function RetornaPeriodos(prd: number): datas
{
  let retdatas: datas = {dtini: undefined, dtfin: undefined};
  let hoje=new Date();
  let ano=hoje.getFullYear();
  let mes=hoje.getMonth();
  switch (prd)
  {
    case 1:
    {
      retdatas.dtini=hoje;
      retdatas.dtfin=hoje;
      break;
    }
    case 2:
    {
      retdatas.dtini=DiminuiDia(hoje);
      retdatas.dtfin=retdatas.dtini;
      break;
    }
    case 3:
    {
      retdatas.dtini=AdicionaDia(hoje);
      retdatas.dtfin=retdatas.dtini;
      break;
    }
    case 4:
    {
      retdatas.dtini=hoje;
      while (retdatas.dtini.getDay()!==0)
      {
        retdatas.dtini=DiminuiDia(retdatas.dtini);
      }
      retdatas.dtfin=hoje;
      while (retdatas.dtfin.getDay()!==6)
      {
        retdatas.dtfin=AdicionaDia(retdatas.dtfin);
      }
      break;
    }
    case 5:
    {
      retdatas.dtini=hoje;
      while (retdatas.dtini.getDay()!==0)
      {
        retdatas.dtini=DiminuiDia(retdatas.dtini);
      }
      retdatas.dtini=DiminuiDia(retdatas.dtini);
      while (retdatas.dtini.getDay()!==0)
      {
        retdatas.dtini=DiminuiDia(retdatas.dtini);
      }
      retdatas.dtfin=DiminuiDia(hoje);
      while (retdatas.dtfin.getDay()!==6)
      {
        retdatas.dtfin=DiminuiDia(retdatas.dtfin);
      }
      break;
    }
    case 6:
    {
      retdatas.dtini=hoje;
      if (retdatas.dtini.getDay()===0)
      {
        retdatas.dtini=AdicionaDia(retdatas.dtini)
      }
      while (retdatas.dtini.getDay()!==0)
      {
        retdatas.dtini=AdicionaDia(retdatas.dtini);
      }
      retdatas.dtfin=retdatas.dtini;
      while (retdatas.dtfin.getDay()!==6)
      {
        retdatas.dtfin=AdicionaDia(retdatas.dtfin);
      }
      break;
    }
    case 7:
    {
      retdatas.dtini=new Date(ano,mes,1);
      retdatas.dtfin=new Date(ano,mes,Ultimodia(ano,mes))
      break;
    }
    case 8:
    {
      mes=mes-1;
      if(mes<0)
      {
        ano=ano-1
        mes=11
      }
      retdatas.dtini=new Date(ano,mes,1);
      retdatas.dtfin=new Date(ano,mes,Ultimodia(ano,mes))
      break;
    }
    case 9:
    {
      mes=mes+1;
      if(mes>11)
      {
        ano=ano+1
        mes=0
      }
      retdatas.dtini=new Date(ano,mes,1);
      retdatas.dtfin=new Date(ano,mes,Ultimodia(ano,mes))
      break;
    }
    case 10:
    {
      retdatas.dtini=new Date(ano,0,1);
      retdatas.dtfin=new Date(ano,11,31)
      break;
    }
    case 11:
    {
      ano=ano-1
      retdatas.dtini=new Date(ano,0,1);
      retdatas.dtfin=new Date(ano,11,31)
      break;
    }
    case 12:
    {
      ano=ano+1
      retdatas.dtini=new Date(ano,0,1);
      retdatas.dtfin=new Date(ano,11,31)
      break;
    }
    default:
    {
      break;
    }
  }
  return retdatas;
}

export interface IPeriodos {id: number, desc: string, tempo: string}

export function ObtemPeriodos (tempos:string): IPeriodos[]
{
  return Periodos.filter(per => tempos.indexOf(per.tempo)!==-1);
}

export const Periodos = [
  {id: 1, desc: 'Hoje', tempo: 'A'},
  {id: 2, desc: 'Ontem', tempo: 'B'},
  {id: 3, desc: 'Amanhã', tempo: 'C'},
  {id: 4, desc: 'Semana atual', tempo: 'A'},
  {id: 5, desc: 'Semana anterior', tempo: 'B'},
  {id: 6, desc: 'Próxima semana', tempo: 'C'},
  {id: 7, desc: 'Mes atual', tempo: 'A'},
  {id: 8, desc: 'Mes anterior', tempo: 'B'},
  {id: 9, desc: 'Próximo mês', tempo: 'C'},
  {id: 10, desc: 'Ano atual', tempo: 'A'},
  {id: 11, desc: 'Ano anterior', tempo: 'B'},
  {id: 12, desc: 'Próximo ano', tempo: 'C'}
]

function DiminuiDia(data:Date):Date
{
  let ano=data.getFullYear();
  let mes=data.getMonth();
  let dia=data.getDate();
  dia=dia-1;
  if (dia<1)
  {
    mes=mes-1
    if(mes<0)
    {
      ano=ano-1;
      mes=11;
    }
    dia=Ultimodia(ano, mes);
  }
  return new Date(ano,mes,dia);
}

function AdicionaDia(data:Date):Date
{
  let ano=data.getFullYear();
  let mes=data.getMonth();
  let dia=data.getDate();
  dia=dia+1;
  if (dia>Ultimodia(ano,mes))
  {
    mes=mes+1
    if(mes>11)
    {
      ano=ano+1;
      mes=0;
    }
    dia=1;
  }
  return new Date(ano,mes,dia);
}

function Ultimodia(ano: number, mes: number): number
{
  return (mes===3 || mes===5 || mes===8 || mes===10) ? 30 : mes===1 ? (Bissexto(ano) ? 29 : 28) : 31
}

function Bissexto(ano: number): boolean
{
  return ((ano % 4) === 0 && ((ano % 100) !== 0 || (ano % 400) ===0));
}

export function ProximoVencimento(dia: number, database: Date): Date | undefined
{
  let ret: Date | undefined = undefined;
  if(dia>0 && dia<32 && database)
  {
    let ano=database.getFullYear();
    let mes=database.getMonth();
    if (dia>=database.getDate())
    {
      ret=new Date(ano, mes, dia)
    }
    else
    {
      mes = mes +1;
      if(mes>11)
      {
        mes = 1;
        ano = ano +1;
      }
      dia = Math.min(dia, Ultimodia(mes, ano));
      ret=new Date(ano, mes, dia);
    }
  }
  return ret;
}

export function DataSql(datacv: Date): Date
{
  return (datacv && datacv.getFullYear) ? new Date(Date.UTC(datacv.getFullYear(), datacv.getMonth(), datacv.getDate(), datacv.getHours(), datacv.getMinutes(), datacv.getSeconds())) : datacv;
}

export function DifDias(pridata?: any, segdata?:any, diasuteis?: boolean, apenaspositivo?:boolean): number
{
  let retdias: number=0;
  if (pridata && segdata)
  {
    let mom2 = Moment(RetornaDataString(segdata));
    if (diasuteis)
    {
      let mom1 = Moment(DiaUtil(pridata));
      retdias = mom2.diff(mom1, 'days');
    }
    if(retdias>0 || !diasuteis)
    {
      let mom1 = Moment(RetornaDataString(pridata));
      retdias = mom2.diff(mom1, 'days');
    }
  }
  return retdias>=0 || !apenaspositivo ? retdias : 0 ;
}

export function DifSegundos(pridata?: any, segdata?:any): number
{
  let retsecs: number=0;
  if (pridata && segdata)
  {
    retsecs=Math.ceil((RetornaDataString(segdata).getTime() -  RetornaDataString(pridata).getTime()) / (1000))
  }
  return retsecs;
}

export function DiaUtil(dtbase: any): Date
{
  let retdata: Date = RetornaDataString(dtbase)
  while (retdata.getDay()===0 || retdata.getDay()===6 || ParametrosDiversos.feriados.some(feriado => RetornaDataAAAAMMDD(feriado.data_fer)===RetornaDataAAAAMMDD(retdata)))
  {
    retdata=AdicionaDia(retdata);
  }
  return retdata;
}

export function Minutos(segundos: number): string
{
 let horas = Math.trunc(segundos/3600);
 let resto = (segundos - horas*3600);
 let minutos = Math.trunc(resto/60);
 let segs = resto - (minutos*60);
 return horas.toString().padStart(2,'0')+':'+minutos.toString().padStart(2,'0')+':'+segs.toString().padStart(2,'0');
}

export const meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

export function a11yProps(index: any) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

export function RetornaClientes (clientes:[], prireg:boolean=true):{id_cli: number, nome_cli: string}[]
{
    let ret:{id_cli: number, nome_cli: string}[]= prireg ? [{id_cli: 0, nome_cli:''}] : [];
    clientes.forEach((cli:any) =>
    {
        ret.push({id_cli: cli.id_cli, nome_cli: cli.id_cli.toString().padStart(6,'0')+' - '+cli.loja_cli.toString()+' - '+cli.nome_pessoa+' - '+cli.fantasia_pessoa});
    })
    return ret;
}

export function RetornaFornecedores (fornecedores:[], prireg:boolean=true):{id_forn: number, nome_forn: string}[]
{
    let ret:{id_forn: number, nome_forn: string}[]= prireg ? [{id_forn: 0, nome_forn:''}] : [];
    fornecedores.forEach((forn:any) =>
    {
        ret.push({id_forn: forn.id_forn, nome_forn: forn.id_forn.toString().padStart(6,'0')+' - '+forn.fantasia_pessoa+' - '+forn.nome_pessoa});
    })
    return ret;
}

export const RetornaValoresGrupos = (vlgr: Valores_Grupos[]) =>
{
  let valgrupos: {id: number, descricao: string}[]=[];
  vlgr.map((vlg:Valores_Grupos) => valgrupos.push({id: vlg.id_vlgr, descricao: vlg.desc_vlgr}))
  return valgrupos;
}

export const achaDescricao = (id: number, arr: any[], colid: string, coldesc: string): string =>
{
  let indice = arr.findIndex((item: any) => item[colid]===id)
  if(indice<0)
  {
    return '';
  }
  return arr[indice][coldesc];
}

export function achavalor (busca: number | string, arr: any[], colbusca: string, colretorna: string, tamanho:number): number | string
{
  let ret: number | string = typeof busca==='number' ? 0 : '';
  let indice = arr.findIndex((item: any) => ((tamanho>0 && typeof busca==='string') ? item[colbusca].padEnd(tamanho,' ').substr(0,tamanho) : item[colbusca])===busca)
  if(indice>=0)
  {
    ret= colretorna==='*index*' ? indice : arr[indice][colretorna];
  }
  else if (colretorna==='*index*')
  {
    ret=-1;
  }
  return ret;
}

const alfabeto='ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

export function GeraHeader(banco: number, identban?:string, nome?: string, seqrem?: number): string
{
  let header='';
  let dtger=new Date();
  identban = (identban ? identban : '').padEnd(20,' ');
  nome = RetiraAcentos((nome ? nome : '').padEnd(30, ' '));
  seqrem = seqrem ? seqrem : 0;
  switch (banco)
  {
    case 237:
    {
      header=('01REMESSA01COBRANCA'.padEnd(26,' '))+identban+nome+('237BRADESCO'.padEnd(18,' '))+RetornaDataDDMMAA(dtger)+('MX'.padStart(10,' '))+(seqrem.toString().padStart(7,'0'))+(' '.padStart(277,' '))+'000001\u000D\u000A';
      break;
    }
    case 341:
    {
      header=('01REMESSA01COBRANCA'.padEnd(26,' '))+identban+nome+('341BANCO ITAU SA'.padEnd(18,' '))+RetornaDataDDMMAA(dtger)+(' '.padStart(294,' '))+'000001\u000D\u000A';
      break;
    }
  }
  return header;
}

export function GeraDetalhe(banco: number, identben:string, recebimento: Recebimentos, cliente: {id_cli: number, cpfcnpj: string, id_pessoa: number, nome_pessoa: string, fantasia_pessoa: string, id_rede: number, nome_rede: string, loja_cli: number, enderecos: {id_enps: number, id_tpen: number, padrao_enps: string, id_end: number, cep_end: string, logr_end: string, numero_end: string, compl_end: string, bairro_end: string, id_cid: number, nome_cid: string, codibge_cid: string, id_uf: number, sigla_uf: string, nome_uf: string, codibge_uf: string}[]}, numreg: number): string
{
  let detalhe='';
  let dtger=new Date();
  let endcob='';
  let cepcob='';
  let baicob='';
  let cidcob='';
  let ufcob='';
  let indcob=cliente.enderecos.findIndex(end => end.id_tpen===ParametrosDiversos.endcobr);
  if(indcob===-1 && cliente.enderecos.length>=1)
  {
    indcob=0;
  }
  if (indcob>=0)
  {
    endcob=', '+cliente.enderecos[indcob].numero_end.trim()+(cliente.enderecos[indcob].compl_end && cliente.enderecos[indcob].compl_end.length>0 ? ' '+cliente.enderecos[indcob].compl_end.trim() : '');
    endcob = (endcob.length<40 ? cliente.enderecos[indcob].logr_end.padEnd(40-endcob.length,' ').substr(0,40-endcob.length).trim() : '')+endcob;
    cepcob=cliente.enderecos[indcob].cep_end;
    baicob=cliente.enderecos[indcob].bairro_end;
    cidcob=cliente.enderecos[indcob].nome_cid;
    ufcob=cliente.enderecos[indcob].sigla_uf;
  }
  endcob=RetiraAcentos(endcob.padEnd(40,' ').substr(0,40));
  cepcob=cepcob.padStart(8,'0').substr(0,8);
  baicob=RetiraAcentos(baicob.padEnd(12,' ').substr(0,12));
  cidcob=RetiraAcentos(cidcob.padEnd(15,' ').substr(0,15));
  ufcob=ufcob.padEnd(2,' ').substr(0,2).normalize();

  switch (banco)
  {
    case 237:
    {
      console.log('geradetalhes: ',(recebimento.jurosdia_rcbt*100).toFixed(0).padStart(17,'0'));
      detalhe=('1'.padEnd(20,'0'))+(identben.padEnd(17,' ').substr(0,17))+(recebimento.idinterno_rcbt.padEnd(25,' ').substr(0,25))+('0'.padEnd(3,'0'))+(ParametrosDiversos.percmulta && ParametrosDiversos.percmulta>0 ? '2'+tamanhoExato((ParametrosDiversos.percmulta*100).toFixed(0),4,'0',true) : '00000')+(recebimento.numban_rcbt.padEnd(12,' ').substr(0,12))+('0'.padEnd(10,'0'))+('2S'.padEnd(16,' '))+'01'+(recebimento.idinterno_rcbt.padEnd(10,' ').substr(0,10))+RetornaDataDDMMAA(recebimento.vencto_rcbt)+tamanhoExato((recebimento.valor_rcbt*100).toFixed(0),13,'0',true)+('5N'.padStart(11,'0'))+RetornaDataDDMMAA(dtger)+tamanhoExato((recebimento.jurosdia_rcbt*100).toFixed(0),17,'0',true)+RetornaDataDDMMAA(recebimento.desconto_rcbt && recebimento.desconto_rcbt>0 ? (recebimento.datadesc_rcbt ? recebimento.datadesc_rcbt : recebimento.vencto_rcbt) : undefined)+tamanhoExato((recebimento.desconto_rcbt*100).toFixed(0),13,'0',true)+'0'.padEnd(26,'0')+(cliente.cpfcnpj.length<14 ? '01' : '02')+(cliente.cpfcnpj.padStart(14,'0'))+RetiraAcentos(cliente.nome_pessoa.padEnd(40,' ').substr(0,40))+(endcob.padEnd(52,' '))+cepcob+RetiraAcentos(ParametrosDiversos.menscobr.padEnd(60,' '))+(numreg.toString().padStart(6,'0'))+'\u000D\u000A';
      break;
    }
    case 341:
    {
      detalhe='102'+(ParametrosDiversos.cnpjusuario.padStart(14,'0').substr(0,14))+(identben.padEnd(16,' ').substr(0,16))+'0000'+(recebimento.idinterno_rcbt.padEnd(25,' ').substr(0,25))+(recebimento.numban_rcbt.padEnd(8,' ').substr(0,8))+('0'.padEnd(13,'0'))+(recebimento.carteira_rcbt.padEnd(24,' ').substr(0,24))+'I01'+(recebimento.idinterno_rcbt.padEnd(10,' ').substr(0,10))+RetornaDataDDMMAA(recebimento.vencto_rcbt)+tamanhoExato((recebimento.valor_rcbt*100).toFixed(0),13,'0',true)+'3410000005N'+RetornaDataDDMMAA(dtger)+'1'+tamanhoExato((recebimento.jurosdia_rcbt*100).toFixed(0),16,'0',true)+RetornaDataDDMMAA(recebimento.desconto_rcbt && recebimento.desconto_rcbt>0 ? (recebimento.datadesc_rcbt ? recebimento.datadesc_rcbt : recebimento.vencto_rcbt) : undefined)+tamanhoExato((recebimento.desconto_rcbt*100).toFixed(0),13,'0',true)+'0'.padEnd(26,'0')+(cliente.cpfcnpj.length<14 ? '01' : '02')+(cliente.cpfcnpj.padStart(14,'0'))+RetiraAcentos(cliente.nome_pessoa.padEnd(30,' ').substr(0,30))+(' '.padStart(10,' '))+endcob+baicob+cepcob+cidcob+(ufcob.padEnd(36,' '))+(' '.padStart(9,'0'))+(numreg.toString().padStart(6,'0'))+'\u000D\u000A';
      break;
    }
  }
  return detalhe;
}

export function GeraTrailler(banco: number, numreg: number): string
{
  let trailler='';
  switch (banco)
  {
    case 237:
    {
      trailler=('9'.padEnd(394,' '))+(numreg.toString().padStart(6,'0'))+'\u000D\u000A';
      break;
    }
    case 341:
    {
      trailler=('9'.padEnd(394,' '))+(numreg.toString().padStart(6,'0'))+'\u000D\u000A';
      break;
    }
  }
  return trailler;
}

export function NumeroBanco(banco: number, agencia: string, conta: string, carteira:string, numero:string): string
{
  let numban='';
  let numlet: string[]=numero.split('/');
  if (numlet.length===2)
  {
    switch (banco)
    {
      case 237:
      {
        let calc=carteira.padStart(2,'0')+(alfabeto.indexOf(numlet[1])+1).toString().padStart(2,'0')+numlet[0].padStart(9,'0');
        let soma=0
        for (let i=0; i<calc.length; i=i+1)
        {
          soma=soma+(parseInt(calc.substr(i,1))*parseInt('2765432765432'.substr(i,1)));
        }
        let resto=soma % 11;
        numban = calc.substr(2,11) + (resto===0 ? '0' : resto===1 ? 'P' : (11-resto).toString());
        break;
      }
      case 341:
      {
        let calc=agencia.padStart(4,'0')+conta.padStart(5,'0')+carteira.padStart(3,'0')+(alfabeto.indexOf(numlet[1])+1).toString().padStart(2,'0')+numlet[0].substr(2,6);
        let soma=0
        for (let i=0; i<calc.length; i=i+1)
        {
          let result=(parseInt(calc.substr(i,1))*parseInt('12121212121212121212'.substr(i,1))).toString().padStart(2,'0');
          soma=soma+parseInt(result.substr(0,1))+parseInt(result.substr(1,1));
        }
        let resto=soma % 10;
        numban = calc.substr(12,18) + (resto===0 ? '0' : (10-resto).toString());
        break;
      }
    }
  }
  return numban;
}

export function RetornaMotivosBanco (motivos: string, banco: number, ocmot:string):string
{
  let retorno:string=''
  if (motivos && banco)
  {
    let indice=OcorrenciasBanco.findIndex(ocbco => ocbco.banco===banco)
    if (indice!==-1)
    {
      let motads: any[] = [];
      let indmot: number=0
      let indmotfn: number=0
      for (let ind=0; ind<motivos.length-1; ind=ind+2)
      {
        let mot=motivos.substr(ind,2);
        if (motads.findIndex(mt => mt===mot)===-1 && (mot!=='00' || ind===0))
        {
          indmot=OcorrenciasBanco[indice].motivo.findIndex(mt => mt.ocorrencia===(ocmot? ocmot : '**'));
          if (indmot!==-1)
          {
            indmotfn=OcorrenciasBanco[indice].motivo[indmot].motivo.findIndex(mt => mt.codigo===mot);
            if (indmotfn!==-1)
            {
              retorno=retorno + (retorno.length===0 ? '' : '/')+OcorrenciasBanco[indice].motivo[indmot].motivo[indmotfn].descricao;
            }
          }
          motads.push(mot);
        }
      }
    }
  }
  return retorno;
}

export function RetornaOcorrenciaBanco (ocor: string, tipo:string, banco: number):string
{
  let retorno:string=''
  if (tipo && ocor && banco)
  {
    if ('12'.indexOf(tipo)!==-1)
    {
      let indice=OcorrenciasBanco.findIndex(ocbco => ocbco.banco===banco)
      if (indice!==-1)
      {
        let indmot=OcorrenciasBanco[indice][tipo==='1' ? 'remessa' : 'retorno'].findIndex(mt => mt.codigo===ocor)
        if (indmot!==-1)
        {
          retorno=OcorrenciasBanco[indice][tipo==='1' ? 'remessa' : 'retorno'][indmot].descricao;
        }
      }
    }
  }
  return retorno;
}

type retornobanco = {
  idinterno: string, 
  numban: string, 
  ocorrencia: string, 
  dataocorrencia: Date,
  valor: number,
  despesas: number,
  desconto: number,
  multa: number,
  juros: number,
  valorpago: number,
  datacredito?: Date,
  motivos: string,
  baixa: boolean,
  protestado: boolean,
  confirmada: boolean
}

export class ConsomeRetornoBancos
{
  public ok: boolean=false;
  public erro: string='';
  public contas: Contas[]=[];
  public id_cta: number=0;
  public nomearq: string='';
  public banco: number=0;
  public registros: retornobanco[] = [];
  public totais: {qtde: number, valor: number, valorpago?: number, label: string}[]=[];

  constructor(ctas: Contas[], strret?:string, nmarq?:string)
  {
    this.contas=ctas;
    if (strret)
    {
      this.LeRegistros(strret,nmarq ? nmarq : '');
    }
  }

  LeRegistros(strret: string, nmarq: string, ctas?:Contas[])
  {
    if (ctas)
    {
      this.contas=ctas;
    }
    this.nomearq=nmarq;
    this.ok=false;
    if (strret.length % 402 !== 0)
    {
      this.erro='Tamanho do arquivo incorreto';
    }
    else
    {
      const header=strret.substr(0,402);
      if (header.substr(0,19).toUpperCase()!=='02RETORNO01COBRANCA')
      {
        this.erro='Arquivo não identificado como retorno';
      }
      else
      {
        this.id_cta=achavalor(strret.substr(26,20),this.contas,'identcob_cta','id_cta',20) as number;
        if(this.id_cta===0)
        {
          this.erro='Conta não encontrada'
        }
        else
        {
          this.banco=parseInt(achaDescricao(this.id_cta,this.contas,'id_cta','banco_cta'));
          if (this.banco!==237 && this.banco!==341)
          {
            this.erro='Banco não suportado'
          }
          else
          {
            this.ok=true;
            let posini=402;
            let reglido='';
            let regdet:retornobanco;
            let seq=2;
            let idtot=0;
            this.registros=[];
            this.totais=[];
            while (posini<strret.length && this.ok)
            {
              reglido=strret.substr(posini,402);
              if (reglido.substr(0,1)==='1')
              {
                if(parseInt(reglido.substr(394,6))!==seq)
                {
                  this.ok=false
                  this.erro='Numero de sequencia incorreto - Registro: '+(seq.toString())+' ('+reglido.substr(394,6)+')';
                }
                else
                {
                  regdet={
                    idinterno: reglido.substr(37,25),
                    numban: reglido.substr(70,12).trim(), 
                    ocorrencia: reglido.substr(108,2), 
                    dataocorrencia: new Date(parseInt('20'+reglido.substr(114,2)),parseInt(reglido.substr(112,2))-1,parseInt(reglido.substr(110,2))),
                    valor: parseInt(reglido.substr(152,13))/100,
                    despesas: (parseInt(reglido.substr(175,13))/100)+(parseInt((reglido.substr(188,13).trim().padStart(13,'0')))/100),
                    desconto: (parseInt(reglido.substr(227,13))/100)+(parseInt(reglido.substr(240,13))/100),
                    multa: 0,
                    juros: parseInt(reglido.substr(266,13))/100,
                    valorpago: parseInt(reglido.substr(253,13))/100,
                    datacredito: reglido.substr(295,6).trim().padStart(6,'0')==='000000' ? undefined : new Date(parseInt('20'+reglido.substr(299,2)),parseInt(reglido.substr(297,2))-1,parseInt(reglido.substr(295,2))),
                    motivos: this.banco===237 ? reglido.substr(318,10) : this.banco===341 ? reglido.substr(377,8)+'00' : '00000000',
                    baixa: this.banco===237 ? ('06,15,17'.indexOf(reglido.substr(108,2))!==-1) : this.banco===341 ? ('06,07,08'.indexOf(reglido.substr(108,2))!==-1) : false,
                    protestado: this.banco===237 ? reglido.substr(108,2)==='10' && reglido.substr(318,2)==='14' : this.banco===341 ? reglido.substr(108,2)==='32' : false,
                    confirmada: this.banco===237 ? reglido.substr(108,2)==='02' && (reglido.substr(318,2)==='00' || reglido.substr(318,2)==='76') : this.banco===341 ? reglido.substr(108,2)==='02'  : false
                  };
                  idtot=this.totais.findIndex(ttl => ttl.label==='***'+regdet.ocorrencia+'***');
                  if(idtot===-1)
                  {
                    this.totais.push({label: '***'+regdet.ocorrencia+'***', qtde:0, valor: 0, valorpago:0});
                    idtot=this.totais.length-1;
                  }
                  this.totais[idtot].qtde=this.totais[idtot].qtde+1;
                  this.totais[idtot].valor=this.totais[idtot].valor+regdet.valor;
                  this.totais[idtot].valorpago=nulltoNumber(this.totais[idtot].valorpago)+regdet.valorpago;
                  this.registros.push(regdet);
                }
              }
              else if (reglido.substr(0,1)==='9')
              {
                switch (this.banco)
                {
                  case 237:
                  {
                    this.totais.push({label: 'Ocorrência 02 - Confirmação de entradas', qtde: parseInt(reglido.substr(57,5)), valor: parseInt(reglido.substr(62,12))/100})
                    this.totais.push({label: 'Ocorrência 06 - Liquidação', qtde: parseInt(reglido.substr(86,5)), valor: parseInt(reglido.substr(91,12))/100})
                    this.totais.push({label: 'Ocorrência 09 e 10 - Títulos baixados', qtde: parseInt(reglido.substr(103,5)), valor: parseInt(reglido.substr(108,12))/100})
                    this.totais.push({label: 'Ocorrência 13 - Abatimento cancelado', qtde: parseInt(reglido.substr(120,5)), valor: parseInt(reglido.substr(125,12))/100})
                    this.totais.push({label: 'Ocorrência 14 - Vencimento alterado', qtde: parseInt(reglido.substr(137,5)), valor: parseInt(reglido.substr(142,12))/100})
                    this.totais.push({label: 'Ocorrência 12 - Abatimento concedido', qtde: parseInt(reglido.substr(154,5)), valor: parseInt(reglido.substr(159,12))/100})
                    this.totais.push({label: 'Ocorrência 19 - Confirmação de instrução de protesto', qtde: parseInt(reglido.substr(171,5)), valor: parseInt(reglido.substr(176,12))/100})
                    break;
                  }
                  case 341:
                  {
                    this.totais.push({label: 'Títulos em cobrança simples ('+reglido.substr(39,8).trim()+')', qtde: parseInt(reglido.substr(17,8)), valor: parseInt(reglido.substr(25,14))/100})
                    this.totais.push({label: 'Títulos em cobrança vinculada ('+reglido.substr(79,8).trim()+')', qtde: parseInt(reglido.substr(57,8)), valor: parseInt(reglido.substr(65,14))/100})
                    this.totais.push({label: 'Títulos em cobrança direta escritural ('+reglido.substr(199,8).trim()+')', qtde: parseInt(reglido.substr(177,8)), valor: parseInt(reglido.substr(185,14))/100})
                    this.totais.push({label: 'Total de registros', qtde: parseInt(reglido.substr(212,8)), valor: parseInt(reglido.substr(220,14))/100})
                    break;
                  }
                }
              }
              else
              {
                this.ok=false
                this.erro='Registro não identificado no arquivo - Registro: '+(seq.toString())+'('+reglido.substr(0,1)+')';
              }
              posini=posini+402;
              seq=seq+1
            }
          }
        }
      }
    }
  }
}

export const OcorrenciasBanco = 
[
  {
    banco: 237,
    remessa: 
    [
      {codigo: '01', descricao: 'Remessa'},
      {codigo: '02', descricao: 'Pedido de baixa'},
      {codigo: '03', descricao: 'Pedido de protesto falimentar'},
      {codigo: '04', descricao: 'Concessão de abatimento'},
      {codigo: '05', descricao: 'Cancelamento de abatimento concedido'},
      {codigo: '06', descricao: 'Alteração de vencimento'},
      {codigo: '07', descricao: 'Alteração do controle do participante'},
      {codigo: '08', descricao: 'Alteração de seu número'},
      {codigo: '09', descricao: 'Pedido de protesto'},
      {codigo: '18', descricao: 'Sustar protesto e baixar Título'},
      {codigo: '19', descricao: 'Sustar protesto e manter em carteira'},
      {codigo: '20', descricao: 'Alteração de valor*'},
      {codigo: '21', descricao: 'Alteração de valor com emissão de boleto (quando a emissão é pelo banco)*'},
      {codigo: '22', descricao: 'Transferência cessão crédito ID. Prod.10'},
      {codigo: '23', descricao: 'Transferência entre carteiras'},
      {codigo: '24', descricao: 'Dev. transferência entre carteiras'},
      {codigo: '31', descricao: 'Alteração de outros dados'},
      {codigo: '32', descricao: 'Instrução de negativação'},
      {codigo: '45', descricao: 'Pedido de negativação'},
      {codigo: '46', descricao: 'Excluir negativação com baixa'},
      {codigo: '47', descricao: 'Excluir negativação e manter pendente'},
      {codigo: '68', descricao: 'Acerto nos dados do rateio de crédito'},
      {codigo: '69', descricao: 'Cancelamento do rateio de crédito'}
    ],
    retorno:
    [
      {codigo: '02', descricao: 'Entrada confirmada'},
      {codigo: '03', descricao: 'Entrada rejeitada'},
      {codigo: '06', descricao: 'Liquidação normal'},
      {codigo: '09', descricao: 'Baixado automat. via arquivo'},
      {codigo: '10', descricao: 'Baixado conforme instruções da agência'},
      {codigo: '11', descricao: 'Em Ser - arquivo de títulos pendentes'},
      {codigo: '12', descricao: 'Abatimento concedido'},
      {codigo: '13', descricao: 'Abatimento cancelado'},
      {codigo: '14', descricao: 'Vencimento alterado'},
      {codigo: '15', descricao: 'Liquidação em cartório'},
      {codigo: '16', descricao: 'Título pago em cheque – vinculado'},
      {codigo: '17', descricao: 'Liquidação após baixa ou título não registrado'},
      {codigo: '18', descricao: 'Acerto de depositária'},
      {codigo: '19', descricao: 'Confirmação receb. inst. de protesto'},
      {codigo: '20', descricao: 'Confirmação recebimento instrução sustação de protesto'},
      {codigo: '21', descricao: 'Acerto do controle do participante'},
      {codigo: '22', descricao: 'Título com pagamento cancelado'},
      {codigo: '23', descricao: 'Entrada do título em cartório'},
      {codigo: '24', descricao: 'Entrada rejeitada por CEP irregular'},
      {codigo: '25', descricao: 'Confirmação receb.Inst.de protesto falimentar'},
      {codigo: '27', descricao: 'Baixa rejeitada'},
      {codigo: '28', descricao: 'Débito de tarifas/custas'},
      {codigo: '29', descricao: 'Ocorrências do pagador'},
      {codigo: '30', descricao: 'Alteração de outros dados rejeitados'},
      {codigo: '32', descricao: 'Instrução rejeitada'},
      {codigo: '33', descricao: 'Confirmação pedido alteração outros dados'},
      {codigo: '34', descricao: 'Retirado de cartório e manutenção carteira'},
      {codigo: '35', descricao: 'Desagendamento do débito automático'},
      {codigo: '40', descricao: 'Estorno de pagamento'},
      {codigo: '55', descricao: 'Sustado judicial'},
      {codigo: '68', descricao: 'Acerto dos dados do rateio de crédito'},
      {codigo: '69', descricao: 'Cancelamento de rateio'},
      {codigo: '73', descricao: 'Confirmação receb. pedido de negativação'},
      {codigo: '74', descricao: 'Confir pedido de excl de negat (com ou sem baixa)'}
    ],
    motivo:
    [
      {
        ocorrencia: '02',
        motivo:
        [
          {codigo: '00', descricao: 'Ocorrência aceita'},
          {codigo: '01', descricao: 'Código do banco inválido'},
          {codigo: '02', descricao: 'Pendente de autorização (autorização débito automático)'},
          {codigo: '03', descricao: 'Pendente de ação do pagador (autorização débito automática – data vencimento)'},
          {codigo: '04', descricao: 'Código do movimento não permitido para a carteira'},
          {codigo: '15', descricao: 'Características da cobrança incompatíveis'},
          {codigo: '17', descricao: 'Data de vencimento anterior a data de emissão'},
          {codigo: '21', descricao: 'Espécie do título inválido'},
          {codigo: '24', descricao: 'Data da emissão inválida'},
          {codigo: '27', descricao: 'Valor/taxa de juros mora inválido'},
          {codigo: '38', descricao: 'Prazo para protesto/ negativação inválido'},
          {codigo: '39', descricao: 'Pedido para protesto/ negativação não permitido para o título'},
          {codigo: '43', descricao: 'Prazo para baixa e devolução inválido'},
          {codigo: '45', descricao: 'Nome do pagador inválido'},
          {codigo: '46', descricao: 'Tipo/num. de inscrição do pagador inválidos'},
          {codigo: '47', descricao: 'Endereço do pagador não informado'},
          {codigo: '48', descricao: 'CEP inválido'},
          {codigo: '50', descricao: 'CEP referente a banco correspondente'},
          {codigo: '53', descricao: 'Nº de inscrição do pagador/avalista inválidos (CPF/CNPJ)'},
          {codigo: '54', descricao: 'Pagador/avalista não informado'},
          {codigo: '67', descricao: 'Débito automático agendado'},
          {codigo: '68', descricao: 'Débito não agendado - erro nos dados de remessa'},
          {codigo: '69', descricao: 'Débito não agendado - pagador não consta no cadastro de autorizante'},
          {codigo: '70', descricao: 'Débito não agendado - beneficiário não autorizado pelo pagador'},
          {codigo: '71', descricao: 'Débito não agendado - beneficiário não participa da modalidade de déb.automático'},
          {codigo: '72', descricao: 'Débito não agendado - código de moeda diferente de R$'},
          {codigo: '73', descricao: 'Débito não agendado - data de vencimento inválida/vencida'},
          {codigo: '75', descricao: 'Débito não agendado - tipo do número de inscrição do pagador debitado inválido'},
          {codigo: '76', descricao: 'Pagador eletrônico DDA - esse motivo somente será disponibilizado no arquivo retorno para as empresas cadastradas nessa condição.'},
          {codigo: '86', descricao: 'Seu número do documento inválido'},
          {codigo: '87', descricao: 'Título baixado por coobrigação e devolvido para carteira'},
          {codigo: '89', descricao: 'Email pagador não enviado – título com débito automático'},
          {codigo: '90', descricao: 'Email pagador não enviado – título de cobrança sem registro'}
        ]
      },
      {
        ocorrencia: '03',
        motivo:
        [
          {codigo: '00', descricao: 'Ocorrência aceita'},
          {codigo: '02', descricao: 'Código do registro detalhe inválido'},
          {codigo: '03', descricao: 'Código da ocorrência inválida'},
          {codigo: '04', descricao: 'Código de ocorrência não permitida para a carteira'},
          {codigo: '05', descricao: 'Código de ocorrência não numérico'},
          {codigo: '07', descricao: 'Agência/conta/digito inválido'},
          {codigo: '08', descricao: 'Nosso número inválido'},
          {codigo: '09', descricao: 'Nosso número duplicado'},
          {codigo: '10', descricao: 'Carteira inválida'},
          {codigo: '13', descricao: 'Identificação da emissão do bloqueto inválida'},
          {codigo: '16', descricao: 'Data de vencimento inválida'},
          {codigo: '18', descricao: 'Vencimento fora do prazo de operação'},
          {codigo: '20', descricao: 'Valor do título inválido'},
          {codigo: '21', descricao: 'Espécie do título inválida'},
          {codigo: '22', descricao: 'Espécie não permitida para a carteira'},
          {codigo: '23', descricao: 'Tipo pagamento não contratado'},
          {codigo: '24', descricao: 'Data de emissão inválida'},
          {codigo: '27', descricao: 'Valor/taxa de juros mora invalido'},
          {codigo: '28', descricao: 'Código do desconto inválido'},
          {codigo: '29', descricao: 'Valor desconto > ou = valor título'},
          {codigo: '32', descricao: 'valor do iof invalido'},
          {codigo: '34', descricao: 'valor do abatimento maior ou igual ao valor do título'},
          {codigo: '38', descricao: 'Prazo para protesto/ negativação inválido'},
          {codigo: '39', descricao: 'Pedido de protesto/negativação não permitida para o título'},
          {codigo: '44', descricao: 'Código da moeda invalido'},
          {codigo: '45', descricao: 'Nome do pagador não informado'},
          {codigo: '46', descricao: 'Tipo/número de inscrição do pagador inválidos'},
          {codigo: '47', descricao: 'Endereço do pagador não informado'},
          {codigo: '48', descricao: 'Cep inválido'},
          {codigo: '49', descricao: 'Cep sem praça de cobrança'},
          {codigo: '50', descricao: 'Cep irregular - banco correspondente'},
          {codigo: '53', descricao: 'Tipo/número de inscrição do sacador avalista inválido'},
          {codigo: '59', descricao: 'Valor/percentual da multa inválido'},
          {codigo: '63', descricao: 'Entrada para título já cadastrado'},
          {codigo: '65', descricao: 'Limite excedido'},
          {codigo: '66', descricao: 'Número autorização inexistente'},
          {codigo: '68', descricao: 'Débito não agendado - erro nos dados de remessa'},
          {codigo: '69', descricao: 'Débito não agendado - pagador não consta no cadastro de autorizante'},
          {codigo: '70', descricao: 'Débito não agendado - beneficiário não autorizado pelo pagador'},
          {codigo: '71', descricao: 'Débito não agendado - beneficiário não participa do débito automático'},
          {codigo: '72', descricao: 'Débito não agendado - código de moeda diferente de R$'},
          {codigo: '73', descricao: 'Débito não agendado - data de vencimento inválida'},
          {codigo: '74', descricao: 'Débito não agendado - conforme seu pedido, título não registrado'},
          {codigo: '75', descricao: 'Débito não agendado – tipo de número de inscrição do debitado inválido'},
          {codigo: '79', descricao: 'Data de juros de mora invalida'},
          {codigo: '80', descricao: 'Data do desconto invalida'},
          {codigo: '86', descricao: 'Seu número invalido'}
        ]
      },
      {
        ocorrencia: '06',
        motivo:
        [
          {codigo: '00', descricao: 'Credito disponível'},
          {codigo: '15', descricao: 'Crédito indisponível'},
          {codigo: '18', descricao: 'Pagamento parcial'},
          {codigo: '42', descricao: 'Rateio não efetuado, cód. calculo 2 (vlr. registro)'}
        ]
      },
      {
        ocorrencia: '09',
        motivo:
        [
          {codigo: '00', descricao: 'Ocorrência aceita'},
          {codigo: '10', descricao: 'Baixa comandada pelo cliente'},
          {codigo: '18', descricao: 'Pagador não aceitou o débito (autorização débito automático)'},
          {codigo: '19', descricao: 'Pendente de ação do pagador (autorização débito automático)'}
        ]
      },
      {
        ocorrencia: '10',
        motivo:
        [
          {codigo: '00', descricao: 'Baixado conforme instruções da agência'},
          {codigo: '14', descricao: 'Título protestado'},
          {codigo: '16', descricao: 'Título baixado pelo banco por decurso prazo'},
          {codigo: '20', descricao: 'Titulo baixado e transferido para desconto'}
        ]
      },
      {
        ocorrencia: '15',
        motivo:
        [
          {codigo: '00', descricao: 'Credito disponível'},
          {codigo: '15', descricao: 'Crédito indisponível'}
        ]
      },
      {
        ocorrencia: '17',
        motivo:
        [
          {codigo: '00', descricao: 'Credito disponível'},
          {codigo: '15', descricao: 'Crédito indisponível'}
        ]
      },
      {
        ocorrencia: '24',
        motivo:
        [
          {codigo: '00', descricao: 'Ocorrência aceita'},
          {codigo: '48', descricao: 'Cep inválido'},
          {codigo: '49', descricao: 'Cep sem praça de cobrança'}
        ]
      },
      {
        ocorrencia: '27',
        motivo:
        [
          {codigo: '00', descricao: 'Ocorrência aceita'},
          {codigo: '02', descricao: 'Código do registro detalhe invalido'},
          {codigo: '04', descricao: 'Código de ocorrência não permitido para a carteira'},
          {codigo: '07', descricao: 'Agência/conta/dígito inválidos'},
          {codigo: '08', descricao: 'Nosso número inválido'},
          {codigo: '09', descricao: 'Nosso número duplicado'},
          {codigo: '10', descricao: 'Carteira inválida'},
          {codigo: '15', descricao: 'Carteira/agência/conta/nosso número inválidos'},
          {codigo: '16', descricao: 'Data vencimento invalida'},
          {codigo: '18', descricao: 'Vencimento fora do prazo de operação'},
          {codigo: '20', descricao: 'Valor título invalido'},
          {codigo: '40', descricao: 'Título com ordem de protesto emitido'},
          {codigo: '42', descricao: 'Código para baixa/devolução inválido'},
          {codigo: '45', descricao: 'Nome do sacado não informado ou invalido'},
          {codigo: '46', descricao: 'Tipo/número de inscrição do sacado invalido'},
          {codigo: '47', descricao: 'Endereço do sacado não informado'},
          {codigo: '48', descricao: 'Cep invalido'},
          {codigo: '60', descricao: 'Movimento para título não cadastrado'},
          {codigo: '77', descricao: 'Transferência para desconto não permitido para a carteira'},
          {codigo: '85', descricao: 'Título com pagamento vinculado'},
          {codigo: '86', descricao: 'Seu número invalido'}
        ]
      },
      {
        ocorrencia: '28',
        motivo:
        [
          {codigo: '02', descricao: 'Tarifa de permanência título cadastrado (*)'},
          {codigo: '03', descricao: 'Tarifa de sustação/excl negativação (*)'},
          {codigo: '04', descricao: 'Tarifa de protesto/incl negativação (*)'},
          {codigo: '08', descricao: 'Custas de protesto'},
          {codigo: '12', descricao: 'Tarifa de registro (*)'},
          {codigo: '13', descricao: 'Tarifa título pago no bradesco (*)'},
          {codigo: '14', descricao: 'Tarifa título pago compensação (*)'},
          {codigo: '15', descricao: 'Tarifa título baixado não pago (*)'},
          {codigo: '16', descricao: 'Tarifa alteração de vencimento (*)'},
          {codigo: '17', descricao: 'Tarifa concessão abatimento (*)'},
          {codigo: '18', descricao: 'Tarifa cancelamento de abatimento (*)'},
          {codigo: '19', descricao: 'Tarifa concessão desconto (*)'},
          {codigo: '20', descricao: 'Tarifa cancelamento desconto (*)'},
          {codigo: '21', descricao: 'Tarifa título pago cics (*)'},
          {codigo: '22', descricao: 'Tarifa título pago internet (*)'},
          {codigo: '23', descricao: 'Tarifa título pago term. gerencial serviços (*)'},
          {codigo: '24', descricao: 'Tarifa título pago pág-contas (*)'},
          {codigo: '25', descricao: 'Tarifa título pago fone fácil (*)'},
          {codigo: '26', descricao: 'Tarifa título déb. postagem (*)'},
          {codigo: '28', descricao: 'Tarifa título pago bdn (*)'},
          {codigo: '29', descricao: 'Tarifa título pago term. multi função (*)'},
          {codigo: '32', descricao: 'Tarifa título pago pagfor (*)'},
          {codigo: '33', descricao: 'Tarifa reg/pgto – guichê caixa (*)'},
          {codigo: '34', descricao: 'Tarifa título pago retaguarda (*)'},
          {codigo: '35', descricao: 'Tarifa título pago subcentro (*)'},
          {codigo: '36', descricao: 'Tarifa título pago cartão de crédito (*)'},
          {codigo: '37', descricao: 'Tarifa título pago comp eletrônica (*)'},
          {codigo: '38', descricao: 'Tarifa título baix. pg. cartório (*)'},
          {codigo: '39', descricao: 'Tarifa título baixado acerto bco (*)'},
          {codigo: '40', descricao: 'Baixa registro em duplicidade (*)'},
          {codigo: '41', descricao: 'Tarifa título baixado decurso prazo (*)'},
          {codigo: '42', descricao: 'Tarifa título baixado judicialmente (*)'},
          {codigo: '43', descricao: 'Tarifa título baixado via remessa (*)'},
          {codigo: '44', descricao: 'Tarifa título baixado rastreamento (*)'},
          {codigo: '45', descricao: 'Tarifa título baixado conf. pedido (*)'},
          {codigo: '46', descricao: 'Tarifa título baixado protestado (*)'},
          {codigo: '47', descricao: 'Tarifa título baixado p/ devolução (*)'},
          {codigo: '48', descricao: 'Tarifa título baixado franco pagto (*)'},
          {codigo: '49', descricao: 'Tarifa título baixado sust/ret/cartório (*)'},
          {codigo: '50', descricao: 'Tarifa título baixado sus/sem/rem/cartório (*)'},
          {codigo: '51', descricao: 'Tarifa título transferido desconto (*)'},
          {codigo: '54', descricao: 'Tarifa baixa por contabilidade (*)'},
          {codigo: '55', descricao: 'Tr. tentativa cons deb aut'},
          {codigo: '56', descricao: 'Tr. credito online'},
          {codigo: '57', descricao: 'Tarifa reg/pagto bradesco expresso'},
          {codigo: '58', descricao: 'Tarifa emissão papeleta'},
          {codigo: '78', descricao: 'Tarifa cadastro cartela instrução permanente (*)'},
          {codigo: '80', descricao: 'Tarifa parcial pagamento compensação (*)'},
          {codigo: '81', descricao: 'Tarifa reapresentação automática título (*)'},
          {codigo: '82', descricao: 'Tarifa registro título déb. automático (*)'},
          {codigo: '83', descricao: 'Tarifa rateio de crédito (*)'},
          {codigo: '89', descricao: 'Tarifa parcial pagamento bradesco(*)'},
          {codigo: '96', descricao: 'Tarifa reg. pagto outras mídias (*)'},
          {codigo: '97', descricao: 'Tarifa reg/pagto – net empresa (*)'},
          {codigo: '98', descricao: 'Tarifa título pago vencido (*)'},
          {codigo: '99', descricao: 'Tr.tít. baixado por decurso prazo (*)'}
        ]
      },
      {
        ocorrencia: '29',
        motivo:
        [
          {codigo: '78', descricao: 'Pagador alega que faturamento e indevido (*)'},
          {codigo: '95', descricao: 'Pagador aceita/reconhece o faturamento (*) '}
        ]
      },
      {
        ocorrencia: '30',
        motivo:
        [
          {codigo: '00', descricao: 'Ocorrência aceita'},
          {codigo: '01', descricao: 'Código do banco inválido'},
          {codigo: '04', descricao: 'Código de ocorrência não permitido para a carteira'},
          {codigo: '05', descricao: 'Código da ocorrência não numérico'},
          {codigo: '08', descricao: 'Nosso número inválido'},
          {codigo: '15', descricao: 'Característica da cobrança incompatível'},
          {codigo: '16', descricao: 'Data de vencimento inválido'},
          {codigo: '17', descricao: 'Data de vencimento anterior a data de emissão'},
          {codigo: '18', descricao: 'Vencimento fora do prazo de operação'},
          {codigo: '20', descricao: 'Valor título invalido'},
          {codigo: '21', descricao: 'Especie título invalida'},
          {codigo: '22', descricao: 'Especie não permitida para a carteira'},
          {codigo: '23', descricao: 'Tipo pagamento não contratado'},
          {codigo: '24', descricao: 'Data de emissão inválida'},
          {codigo: '26', descricao: 'Código de juros de mora inválido (*)'},
          {codigo: '27', descricao: 'Valor/taxa de juros de mora inválido'},
          {codigo: '28', descricao: 'Código de desconto inválido'},
          {codigo: '29', descricao: 'Valor do desconto maior/igual ao valor do título'},
          {codigo: '30', descricao: 'Desconto a conceder não confere'},
          {codigo: '31', descricao: 'Concessão de desconto já existente ( desconto anterior )'},
          {codigo: '32', descricao: 'Valor do iof inválido'},
          {codigo: '33', descricao: 'Valor do abatimento inválido'},
          {codigo: '34', descricao: 'Valor do abatimento maior/igual ao valor do título'},
          {codigo: '36', descricao: 'Concessão abatimento'},
          {codigo: '38', descricao: 'Prazo para protesto/ negativação inválido'},
          {codigo: '39', descricao: 'Pedido para protesto/ negativação não permitido para o título'},
          {codigo: '40', descricao: 'Título com ordem/pedido de protesto/negativação emitido'},
          {codigo: '42', descricao: 'Código para baixa/devolução inválido'},
          {codigo: '43', descricao: 'Prazo para baixa/devolução invalido'},
          {codigo: '46', descricao: 'Tipo/número de inscrição do pagador inválidos'},
          {codigo: '48', descricao: 'Cep inválido'},
          {codigo: '53', descricao: 'Tipo/número de inscrição do pagador/avalista inválidos'},
          {codigo: '54', descricao: 'Pagador/avalista não informado'},
          {codigo: '57', descricao: 'Código da multa inválido'},
          {codigo: '58', descricao: 'Data da multa inválida'},
          {codigo: '60', descricao: 'Movimento para título não cadastrado'},
          {codigo: '79', descricao: 'Data de juros de mora inválida'},
          {codigo: '80', descricao: 'Data do desconto inválida'},
          {codigo: '85', descricao: 'Título com pagamento vinculado.'},
          {codigo: '88', descricao: 'E-mail pagador não lido no prazo 5 dias'},
          {codigo: '91', descricao: 'E-mail pagador não recebido'}
        ]
      },
      {
        ocorrencia: '32',
        motivo:
        [
          {codigo: '00', descricao: 'Ocorrência aceita'},
          {codigo: '01', descricao: 'Código do banco inválido'},
          {codigo: '02', descricao: 'Código registro detalhe invalido'},
          {codigo: '04', descricao: 'Código de ocorrência não permitido para a carteira'},
          {codigo: '05', descricao: 'Código de ocorrência não numérico'},
          {codigo: '06', descricao: 'Espécie bdp, não aceita pagamento parcial'},
          {codigo: '07', descricao: 'Agência/conta/dígito inválidos'},
          {codigo: '08', descricao: 'Nosso número inválido'},
          {codigo: '10', descricao: 'Carteira inválida'},
          {codigo: '15', descricao: 'Características da cobrança incompatíveis'},
          {codigo: '16', descricao: 'Data de vencimento inválida'},
          {codigo: '17', descricao: 'Data de vencimento anterior a data de emissão'},
          {codigo: '18', descricao: 'Vencimento fora do prazo de operação'},
          {codigo: '20', descricao: 'Valor do título inválido'},
          {codigo: '21', descricao: 'Espécie do título inválida'},
          {codigo: '22', descricao: 'Espécie não permitida para a carteira'},
          {codigo: '23', descricao: 'Tipo pagamento não contratado'},
          {codigo: '24', descricao: 'Data de emissão inválida'},
          {codigo: '26', descricao: 'Código juros mora invalido'},
          {codigo: '27', descricao: 'Valor/taxa juros mira invalido'},
          {codigo: '28', descricao: 'Código de desconto inválido'},
          {codigo: '29', descricao: 'Valor do desconto maior/igual ao valor do título'},
          {codigo: '30', descricao: 'Desconto a conceder não confere'},
          {codigo: '31', descricao: 'Concessão de desconto - já existe desconto anterior'},
          {codigo: '33', descricao: 'Valor do abatimento inválido'},
          {codigo: '34', descricao: 'Valor do abatimento maior/igual ao valor do título'},
          {codigo: '36', descricao: 'Concessão abatimento - já existe abatimento anterior'},
          {codigo: '38', descricao: 'Prazo para protesto/ negativação inválido'},
          {codigo: '39', descricao: 'Pedido para protesto/ negativação não permitido para o título'},
          {codigo: '40', descricao: 'Título com ordem/pedido de protesto/negativação emitido'},
          {codigo: '41', descricao: 'Pedido de sustação/excl p/ título sem instrução de protesto/negativação'},
          {codigo: '45', descricao: 'Nome do pagador não informado'},
          {codigo: '46', descricao: 'Tipo/número de inscrição do pagador inválidos'},
          {codigo: '47', descricao: 'Endereço do pagador não informado'},
          {codigo: '48', descricao: 'Cep inválido'},
          {codigo: '50', descricao: 'Cep referente a um banco correspondente'},
          {codigo: '52', descricao: 'Unidade da federação invalida'},
          {codigo: '53', descricao: 'Tipo de inscrição do pagador avalista inválidos'},
          {codigo: '60', descricao: 'Movimento para título não cadastrado'},
          {codigo: '65', descricao: 'Limite excedido'},
          {codigo: '66', descricao: 'Numero autorização inexistente'},
          {codigo: '85', descricao: 'Título com pagamento vinculado'},
          {codigo: '86', descricao: 'Seu número inválido'},
          {codigo: '94', descricao: 'Título cessão fiduciária – instrução não liberada pela agência'},
          {codigo: '97', descricao: 'Instrução não permitida título negativado'},
          {codigo: '98', descricao: 'Inclusão bloqueada face a determinação judicial'},
          {codigo: '99', descricao: 'Telefone beneficiário não informado / inconsistente'}
        ]
      },
      {
        ocorrencia: '35',
        motivo:
        [
          {codigo: '81', descricao: 'Tentativas esgotadas, baixado'},
          {codigo: '82', descricao: 'Tentativas esgotadas, pendente'},
          {codigo: '83', descricao: 'Cancelado pelo Pagador e Mantido Pendente, conforme negociação (*)'},
          {codigo: '84', descricao: 'Cancelado pelo pagador e baixado, conforme negociação (*)'}
        ]
      }
    ]
  },
  {
    banco: 341,
    remessa: 
    [
      {codigo: '01', descricao: 'Remessa'},
      {codigo: '02', descricao: 'Pedido de baixa'},
      {codigo: '04', descricao: 'Concessão de abatimento (indicador 12.5)'},
      {codigo: '05', descricao: 'Cancelamento de abatimento'},
      {codigo: '06', descricao: 'Alteração de vencimento'},
      {codigo: '07', descricao: 'Alteração do uso da empresa'},
      {codigo: '08', descricao: 'Alteração de seu número'},
      {codigo: '09', descricao: 'Protestar'},
      {codigo: '10', descricao: 'Não protestar'},
      {codigo: '11', descricao: 'Protesto para fins falimentares'},
      {codigo: '18', descricao: 'Sustar o protesto'},
      {codigo: '30', descricao: 'Exclusão de sacador avalista'},
      {codigo: '31', descricao: 'Alteração de outros dados'},
      {codigo: '34', descricao: 'Baixa por ter sido pago diretamente ao beneficiário'},
      {codigo: '35', descricao: 'Cancelamento de instrução'},
      {codigo: '37', descricao: 'Alteração do vencimento e sustar protesto'},
      {codigo: '38', descricao: 'Beneficiário não concorda com alegação do pagador'},
      {codigo: '47', descricao: 'Beneficiário solicita dispensa de juros'},
      {codigo: '49', descricao: 'Alteração de dados extras (registro de multa)'},
      {codigo: '66', descricao: 'Entrada em negativação expressa'},
      {codigo: '67', descricao: 'Não negativar (inibe a entrada em negativação expressa)'},
      {codigo: '68', descricao: 'Excluir negativação expressa (até 15 dias corridos após a entrada em negativação expressa)'},
      {codigo: '69', descricao: 'Cancelar negativação expressa (após título ter sido negativado)'},
      {codigo: '93', descricao: 'Descontar títulos encaminhados no dia'}
    ],
    retorno:
    [
      {codigo: '02', descricao: 'Entrada Confirmada com possibilidade de mensagem'},
      {codigo: '03', descricao: 'Entrada Rejeitada'},
      {codigo: '04', descricao: 'Alteração de dados - nova entrada ou alteração/exclusão de dados acatada'},
      {codigo: '05', descricao: 'Alteração de dados - baixa'},
      {codigo: '06', descricao: 'Liquidação normal'},
      {codigo: '07', descricao: 'Liquidação parcial - cobrança inteligente (B2B)'},
      {codigo: '08', descricao: 'Liquidação em cartório'},
      {codigo: '09', descricao: 'Baixa simples'},
      {codigo: '10', descricao: 'Baixa por ter sido liquidado'},
      {codigo: '11', descricao: 'Em Ser (só no retorno mensal)'},
      {codigo: '12', descricao: 'Abatimento Concedido'},
      {codigo: '13', descricao: 'Abatimento Cancelado'},
      {codigo: '14', descricao: 'Vencimento Alterado'},
      {codigo: '15', descricao: 'Baixas rejeitadas'},
      {codigo: '16', descricao: 'Instruções rejeitadas'},
      {codigo: '17', descricao: 'Alteração/exclusão de dados rejeitados'},
      {codigo: '18', descricao: 'Cobrança contratual - instruções/alterações rejeitadas/pendentes'},
      {codigo: '19', descricao: 'Confirma recebimento de instrução de protesto'},
      {codigo: '20', descricao: 'Confirma recebimento de instrução de sustação de protesto/tarifa'},
      {codigo: '21', descricao: 'Confirma recebimento de instrução de não protestar'},
      {codigo: '23', descricao: 'Titulo enviado à cartório/tarifa'},
      {codigo: '24', descricao: 'Instrução de protesto rejeitada / sustada / pendente'},
      {codigo: '25', descricao: 'Alegações do pagador'},
      {codigo: '26', descricao: 'Tarifa de aviso de cobrança'},
      {codigo: '27', descricao: 'Tarifa de extrato posição (B40X)'},
      {codigo: '28', descricao: 'Tarifa de relação das liquidações'},
      {codigo: '29', descricao: 'Tarifa de manutenção de títulos vencidos'},
      {codigo: '30', descricao: 'Débito mensal de tarifas (para entradas e baixas)'},
      {codigo: '32', descricao: 'Baixa por ter sido protestado'},
      {codigo: '33', descricao: 'Custas de protesto'},
      {codigo: '34', descricao: 'Custas de sustação'},
      {codigo: '35', descricao: 'Custas de cartório distribuidor'},
      {codigo: '36', descricao: 'Custas de edital'},
      {codigo: '37', descricao: 'Tarifa de emissão de boleto/tarifa de envio de duplicata'},
      {codigo: '38', descricao: 'Tarifa de instrução'},
      {codigo: '39', descricao: 'Tarifa de ocorrências'},
      {codigo: '40', descricao: 'Tarifa mensal de emissão de boleto/tarifa mensal de envio de duplicata'},
      {codigo: '41', descricao: 'Débito mensal de tarifas - extrato de poisção (B4EP/B4OX)'},
      {codigo: '42', descricao: 'Débito mensal de tarifas - outras instruções'},
      {codigo: '43', descricao: 'Débito mensal de tarifas - manutenção de títulos vencidos'},
      {codigo: '44', descricao: 'Débito mensal de tarifas - outras ocorrências'},
      {codigo: '45', descricao: 'Débito mensal de tarifas - protesto'},
      {codigo: '46', descricao: 'Débito mensal de tarifas - sustação de protesto'},
      {codigo: '47', descricao: 'Baixa com transferência para desconto'},
      {codigo: '48', descricao: 'Custas de sustação judicial'},
      {codigo: '51', descricao: 'Tarifa mensal ref a entradas bancos correspondentes na carteira'},
      {codigo: '52', descricao: 'Tarifa mensal baixas na carteira'},
      {codigo: '53', descricao: 'Tarifa mensal baixas em bancos correspondentes na carteira'},
      {codigo: '54', descricao: 'Tarifa mensal de liquidações na carteira'},
      {codigo: '55', descricao: 'tarifa mensal de liquidações em bancos correspondentes na carteira'},
      {codigo: '56', descricao: 'Custas de irregularidade'},
      {codigo: '57', descricao: 'Instrução cancelada'},
      {codigo: '58', descricao: 'Baixa por crédito em C/C através do SISPAG'},
      {codigo: '60', descricao: 'Entrada rejeitada carnê'},
      {codigo: '61', descricao: 'Tarifa emissão aviso de movimentação de títulos (2154)'},
      {codigo: '62', descricao: 'Débito mensal de tarifa - aviso de movimentação de títulos (2154)'},
      {codigo: '63', descricao: 'Título sustado judicialmente'},
      {codigo: '64', descricao: 'Entrada confirmada com rateio de crédito'},
      {codigo: '65', descricao: 'Pagametno com cheque - aguardando compensação'},
      {codigo: '69', descricao: 'Cheque devolvido'},
      {codigo: '71', descricao: 'Entrada registrada, aguardando avaliação'},
      {codigo: '72', descricao: 'Baixa por crédito em C/C através do SISPAG sem título correspondente'},
      {codigo: '73', descricao: 'Confirmação de entrada na cobrança simples - entrada não aceita na cobrança contratual'},
      {codigo: '74', descricao: 'Instrução de negativação expressa rejeitada'},
      {codigo: '75', descricao: 'Confirmação de recebimento de instrução de entrada em negativação expressa'},
      {codigo: '76', descricao: 'Cheque compensado'},
      {codigo: '77', descricao: 'Confirmação de recebimento de instrução de exclusão de entrada em negativação expressa'},
      {codigo: '78', descricao: 'Confirmação de recebimento de instrução de cancelamento de negativação expressa'},
      {codigo: '79', descricao: 'Negativação expressa informacional'},
      {codigo: '80', descricao: 'Confirmação de entrada em negativação expressa - tarifa'},
      {codigo: '82', descricao: 'Confirmação do cancelamento de negativação expressa - tarifa'},
      {codigo: '83', descricao: 'Confirmação de exclusão de entrada em negativação expressa por liquidação - tarifa'},
      {codigo: '85', descricao: 'Tarifa por boleto (até 03 envios) cobrança ativa eletrônica'},
      {codigo: '86', descricao: 'Tarifa email cobrança ativa eletrônica'},
      {codigo: '87', descricao: 'Tarifa SMS cobrança ativa eletrônica'},
      {codigo: '88', descricao: 'Tarifa mensal por boleto (até 03 envios) cobrança ativa eletrônica'},
      {codigo: '89', descricao: 'Tarifa mensal email cobrança ativa eletrônica'},
      {codigo: '90', descricao: 'Tarifa mensal SMS cobrança ativa eletrônica'},
      {codigo: '91', descricao: 'Tarifa mensal de exclusão de entrada de negativação expressa'},
      {codigo: '92', descricao: 'Tarifa mensal de cancelamento de negativação expressa'},
      {codigo: '93', descricao: 'Tarifa mensal de exclusão de negativação expressa por liquidação'}
    ],
    motivo:
    [
      {
        ocorrencia: '03',
        motivo:
        [
          {codigo: '03', descricao: 'Ag. cobradora - cep sem atendimento de protesto no momento'},
          {codigo: '04', descricao: 'Estado - sigla do estado inválida'},
          {codigo: '05', descricao: 'Data vencimento - prazo da operação menor que prazo mínimo ou maior que o máximo'},
          {codigo: '07', descricao: 'Valor do título - valor do título maior que 10.000.000,00'},
          {codigo: '08', descricao: 'Nome do pagador - não informado ou deslocado'},
          {codigo: '09', descricao: 'Agencia/conta - agência encerrada'},
          {codigo: '10', descricao: 'Logradouro - não informado ou deslocado'},
          {codigo: '11', descricao: 'Cep - cep não numérico ou cep inválido'},
          {codigo: '12', descricao: 'Sacador / avalista - nome não informado ou deslocado (bancos correspondentes)'},
          {codigo: '13', descricao: 'Estado/cep - cep incompatível com a sigla do estado'},
          {codigo: '14', descricao: 'Nosso número - nosso número já registrado no cadastro do banco ou fora da faixa'},
          {codigo: '15', descricao: 'Nosso número - nosso número em duplicidade no mesmo movimento'},
          {codigo: '18', descricao: 'Data de entrada - data de entrada inválida para operar com esta carteira'},
          {codigo: '19', descricao: 'Ocorrência - ocorrência inválida'},
          {codigo: '21', descricao: 'Ag. cobradora - carteira não aceita depositária correspondente / estado da agência diferente do estado do pagador / ag. cobradora não consta no cadastro ou encerrando'},
          {codigo: '22', descricao: 'Carteira - carteira não permitida (necessário cadastrar faixa livre)'},
          {codigo: '26', descricao: 'Agência/conta - agência/conta não liberada para operar com cobrança'},
          {codigo: '27', descricao: 'Cnpj inapto - cnpj do beneficiário inapto devolução de título em garantia'},
          {codigo: '29', descricao: 'Código empresa - categoria da conta inválida'},
          {codigo: '30', descricao: 'Entrada bloqueada - entradas bloqueadas, conta suspensa em cobrança'},
          {codigo: '31', descricao: 'Agência/conta - conta não tem permissão para protestar (contate seu gerente)'},
          {codigo: '35', descricao: 'Valor do iof - iof maior que 5%'},
          {codigo: '36', descricao: 'Qtdade de moeda - quantidade de moeda incompatível com valor do título'},
          {codigo: '37', descricao: 'Cnpj/cpf do pagador - não numérico ou igual a zeros'},
          {codigo: '42', descricao: 'Nosso número - nosso número fora de faixa'},
          {codigo: '52', descricao: 'Ag. cobradora - empresa não aceita banco correspondente'},
          {codigo: '53', descricao: 'Ag. cobradora - empresa não aceita banco correspondente - cobrança mensagem'},
          {codigo: '54', descricao: 'Data de vencto - banco correspondente - título com vencimento inferior a 15 dias'},
          {codigo: '55', descricao: 'Dep/bco corresp - cep não pertence à depositária informada'},
          {codigo: '56', descricao: 'Dt vencto/bco corresp - vencto superior a 180 dias da data de entrada'},
          {codigo: '57', descricao: 'Data de vencto - cep só depositária bco do brasil com vencto inferior a 8 dias'},
          {codigo: '60', descricao: 'Abatimento - valor do abatimento inválido'},
          {codigo: '61', descricao: 'Juros de mora - juros de mora maior que o permitido'},
          {codigo: '62', descricao: 'Desconto - valor do desconto maior que valor do título'},
          {codigo: '63', descricao: 'Desconto de antecipação - valor da importância por dia de desconto (idd) não permitido'},
          {codigo: '64', descricao: 'Data de emissão - data de emissão do título inválida'},
          {codigo: '65', descricao: 'Taxa financto - taxa inválida (vendor)'},
          {codigo: '66', descricao: 'Data de vencto - invalida/fora de prazo de operação (mínimo ou máximo)'},
          {codigo: '67', descricao: 'Valor/qtidade - valor do título/quantidade de moeda inválido'},
          {codigo: '68', descricao: 'Carteira - carteira inválida ou não cadastrada no intercâmbio da cobrança'},
          {codigo: '69', descricao: 'Carteira - carteira inválida para títulos com rateio de crédito'},
          {codigo: '70', descricao: 'Agência/conta - beneficiário não cadastrado para fazer rateio de crédito'},
          {codigo: '78', descricao: 'Agência/conta - duplicidade de agência/conta beneficiária do rateio de crédito'},
          {codigo: '80', descricao: 'Agência/conta - quantidade de contas beneficiárias do rateio maior do que o permitido (máximo de 30 contas por título)'},
          {codigo: '81', descricao: 'Agência/conta - conta para rateio de crédito inválida / não pertence ao itaú'},
          {codigo: '82', descricao: 'Desconto/abatimento - desconto/abatimento não permitido para títulos com rateio de crédito'},
          {codigo: '83', descricao: 'Valor do título - valor do título menor que a soma dos valores estipulados para rateio'},
          {codigo: '84', descricao: 'Agência/conta - agência/conta beneficiária do rateio é a centralizadora de crédito do beneficiário'},
          {codigo: '85', descricao: 'Agência/conta - agência/conta do beneficiário é contratual / rateio de crédito não permitido'},
          {codigo: '86', descricao: 'Tipo de valor - código do tipo de valor inválido / não previsto para títulos com rateio de crédito'},
          {codigo: '87', descricao: 'Agência/conta - registro tipo 4 sem informação de agências/contas beneficiárias do rateio'},
          {codigo: '90', descricao: 'Nro da linha - cobrança mensagem - número da linha da mensagem inválido ou quantidade de linhas excedidas'},
          {codigo: '97', descricao: 'Sem mensagem - cobrança mensagem sem mensagem (só de campos fixos), porém com registro do tipo 7 ou 8'},
          {codigo: '98', descricao: 'Flash inválido - registro mensagem sem flash cadastrado ou flash informado diferente do cadastrado'},
          {codigo: '99', descricao: 'Flash inválido - conta de cobrança com flash cadastrado e sem registro de mensagem correspondente'}
        ]
      },
      {
        ocorrencia: '15',
        motivo:
        [
          {codigo: '01', descricao: 'Carteira/nº número não numérico'},
          {codigo: '04', descricao: 'Nosso número em duplicidade no mesmo movimento'},
          {codigo: '05', descricao: 'Solicitação de baixa para título já baixado ou liquidado'},
          {codigo: '06', descricao: 'Solicitação de baixa para título não registrado no sistema'},
          {codigo: '07', descricao: 'Cobrança prazo curto - solicitação de baixa p/ título não registrado no sistema'},
          {codigo: '08', descricao: 'Solicitação de baixa para título em floating'},
          {codigo: '10', descricao: 'Valor do título faz parte de garantia de empréstimo'},
          {codigo: '11', descricao: 'Pago através do SISPAG por crédito em C/C e não baixado'}
        ]
      },
      {
        ocorrencia: '16',
        motivo:
        [
          {codigo: '01', descricao: 'Instrução/ocorrência não existente'},
          {codigo: '03', descricao: 'Conta não tem permissão para protestar (contate seu gerente)'},
          {codigo: '06', descricao: 'Nosso número igual a zeros'},
          {codigo: '09', descricao: 'Cnpj/cpf do sacador/avalista inválido'},
          {codigo: '10', descricao: 'Valor do abatimento igual ou maior que o valor do título'},
          {codigo: '11', descricao: 'Segunda instrução/ocorrência não existente'},
          {codigo: '14', descricao: 'Registro em duplicidade'},
          {codigo: '15', descricao: 'Cnpj/cpf informado sem nome do sacador/avalista'},
          {codigo: '19', descricao: 'Valor do abatimento maior que 90% do valor do título'},
          {codigo: '20', descricao: 'Existe sustacao de protesto pendente para o titulo'},
          {codigo: '21', descricao: 'Título não registrado no sistema'},
          {codigo: '22', descricao: 'Título baixado ou liquidado'},
          {codigo: '23', descricao: 'Instrução não aceita'},
          {codigo: '24', descricao: 'Instrução incompatível – existe instrução de protesto para o título'},
          {codigo: '25', descricao: 'Instrução incompatível – não existe instrução de protesto para o título'},
          {codigo: '26', descricao: 'Instrução não aceita por já ter sido emitida a ordem de protesto ao cartório'},
          {codigo: '27', descricao: 'Instrução não aceita por não ter sido emitida a ordem de protesto ao cartório'},
          {codigo: '28', descricao: 'Já existe uma mesma instrução cadastrada anteriormente para o título'},
          {codigo: '29', descricao: 'Valor líquido + valor do abatimento diferente do valor do título registrado'},
          {codigo: '30', descricao: 'Existe uma instrução de não protestar ativa para o título'},
          {codigo: '31', descricao: 'Existe uma ocorrência do pagador que bloqueia a instrução'},
          {codigo: '32', descricao: 'Depositária do título = 9999 ou carteira não aceita protesto'},
          {codigo: '33', descricao: 'Alteração de vencimento igual à registrada no sistema ou que torna o título vencido'},
          {codigo: '34', descricao: 'Instrução de emissão de aviso de cobrança para título vencido antes do vencimento'},
          {codigo: '35', descricao: 'Solicitação de cancelamento de instrução inexistente'},
          {codigo: '36', descricao: 'Título sofrendo alteração de controle (agência/conta/carteira/nosso número)'},
          {codigo: '37', descricao: 'Instrução não permitida para a carteira'},
          {codigo: '38', descricao: 'Instrução não permitida para título com rateio de crédito'},
          {codigo: '40', descricao: 'Instrução incompatível – não existe instrução de negativação expressa para o título'},
          {codigo: '41', descricao: 'Instrução não permitida – título com entrada em negativação expressa'},
          {codigo: '42', descricao: 'Instrução não permitida – título com negativação expressa concluída'},
          {codigo: '43', descricao: 'Prazo inválido para negativação expressa – mínimo: 02 dias corridos após o vencimento'},
          {codigo: '45', descricao: 'Instrução incompatível para o mesmo título nesta data'},
          {codigo: '47', descricao: 'Instrução não permitida – espécie inválida'},
          {codigo: '48', descricao: 'Dados do pagador inválidos ( cpf / cnpj / nome )'},
          {codigo: '49', descricao: 'Dados do endereço do pagador inválidos'},
          {codigo: '50', descricao: 'Data de emissão do título inválida'},
          {codigo: '51', descricao: 'Instrução não permitida – título com negativação expressa agendada'}
        ]
      },
      {
        ocorrencia: '17',
        motivo:
        [
          {codigo: '02', descricao: 'Agência cobradora inválida ou com o mesmo conteúdo'},
          {codigo: '04', descricao: 'Sigla do estado inválida'},
          {codigo: '05', descricao: 'Data de vencimento inválida ou com o mesmo conteúdo'},
          {codigo: '06', descricao: 'Valor do título com outra alteração simultânea'},
          {codigo: '08', descricao: 'Nome do pagador com o mesmo conteúdo'},
          {codigo: '09', descricao: 'Agência/conta incorreta'},
          {codigo: '11', descricao: 'Cep inválido'},
          {codigo: '12', descricao: 'Número inscrição inválido do sacador avalista'},
          {codigo: '13', descricao: 'Seu número com o mesmo conteúdo'},
          {codigo: '16', descricao: 'Abatimento/alteração do valor do título ou solicitação de baixa bloqueada'},
          {codigo: '20', descricao: 'Espécie inválida'},
          {codigo: '21', descricao: 'Agência cobradora não consta no cadastro de depositária ou em encerramento'},
          {codigo: '23', descricao: 'Data de emissão do título inválida ou com mesmo conteúdo'},
          {codigo: '41', descricao: 'Campo aceite inválido ou com mesmo conteúdo'},
          {codigo: '42', descricao: 'Alteração inválida para título vencido'},
          {codigo: '43', descricao: 'Alteração bloqueada – vencimento já alterado'},
          {codigo: '53', descricao: 'Instrução com o mesmo conteúdo'},
          {codigo: '54', descricao: 'Data vencimento para bancos correspondentes inferior ao aceito pelo banco'},
          {codigo: '55', descricao: 'Alterações iguais para o mesmo controle (agência/conta/carteira/nosso número)'},
          {codigo: '56', descricao: 'Cnpj/cpf inválido não numérico ou zerado'},
          {codigo: '57', descricao: 'Prazo de vencimento inferior a 15 dias'},
          {codigo: '60', descricao: 'Valor de iof – alteração não permitida para carteiras de n.s. – moeda variável'},
          {codigo: '61', descricao: 'Título já baixado ou liquidado ou não existe título correspondente no sistema'},
          {codigo: '66', descricao: 'Alteração não permitida para carteiras de notas de seguros – moeda variável'},
          {codigo: '67', descricao: 'Nome inválido do sacador avalista'},
          {codigo: '72', descricao: 'Endereço inválido – sacador avalista'},
          {codigo: '73', descricao: 'Bairro inválido – sacador avalista'},
          {codigo: '74', descricao: 'Cidade inválida – sacador avalista'},
          {codigo: '75', descricao: 'Sigla estado inválido – sacador avalista'},
          {codigo: '76', descricao: 'Cep inválido – sacador avalista'},
          {codigo: '81', descricao: 'Alteração bloqueada – título com negativação expressa / protesto'},
          {codigo: '87', descricao: 'Alteração bloqueada – título com rateio de crédito'}
        ]
      },
      {
        ocorrencia: '18',
        motivo:
        [
          {codigo: '16', descricao: 'Abatimento/alteração do valor do título ou solicitação de baixa bloqueados'},
          {codigo: '40', descricao: 'Não aprovada devido ao impacto na elegibilidade de garantias'},
          {codigo: '41', descricao: 'Automaticamente rejeitada'},
          {codigo: '42', descricao: 'Confirma recebimento de instrução - pendente de análise'}
        ]
      }
    ]
  }
]

export function CriaAutorizacao(palchave: string, database: Date): string
{
  let autor='';
  let mes=database.getMonth();
  let ano=database.getFullYear();
  if(database && palchave && database.getDate && palchave.length===7 && palchave!=='0000000')
  {
    if(database.getDate()>15)
    {
      mes=mes+2;
      if(mes>12)
      {
        mes=1
        ano=ano+1;
      }
    }
    else
    {
      mes=mes+1
    }
    let semzero='';
    let soma=0;
    for(let i=0;i<7;i=i+1)
    {
      if(palchave.substr(i,1)!=='0')
      {
        semzero=semzero+palchave.substr(i,1);
      }
      soma=soma+parseInt(palchave.substr(i,1));
    }
    let sominv=parseInt(soma.toString().padStart(2,'0').substr(1,1)+soma.toString().padStart(2,'0').substr(0,1));
    if (sominv>80)
    {
      sominv=sominv-80;
    }
    let vlr1=parseInt(mes.toString().padStart(2,'0').substr(1,1));
    let vlr2=parseInt((ano-2000).toString().padStart(2,'0').substr(1,1));
    let vlr3=parseInt(mes.toString().padStart(2,'0').substr(0,1));
    let vlr4=parseInt((ano-2000).toString().padStart(2,'0').substr(0,1));
    let mult=((vlr1<1 ? 10 : vlr1)*(vlr2<1 ? 10 : vlr2))+(vlr3<1 ? 10 : vlr3)+(vlr4<1 ? 10 : vlr4)
    mult = mult>100 ? mult-80 : mult;
    let cfr=mult.toString().padStart(2,'0').substr(1,1)+((mes+soma).toString().padStart(2,'0'))+(((ano-2000)+sominv).toString().padStart(2,'0'))+(mult.toString().padStart(2,'0').substr(0,1));
    mult=parseInt(mes.toString().padStart(2,'0').substr(1,1));
    mult= mult<1 ? 10 : mult;
    while (mult>semzero.length)
    {
      mult=mult-semzero.length;
    }
    mult=parseInt(semzero.substr(mult-1,1));
    autor=cfr.substr(0,5)+(mult*(soma+sominv)).toString().trim()+cfr.substr(5,1);
  }
  return autor;
}

export abstract class ParametrosDiversos
{
  public static tiposgeracao: {id: string, desc: string}[] = [{id: 'A', desc: 'Mensal'},{id: 'B', desc: 'Única'},{id: 'C', desc: 'Lançamentos'}];
  public static tiposservico: {id: string, desc: string}[] = [{id: 'A', desc: 'Mensal'},{id: 'B', desc: 'Única'},{id: 'C', desc: 'Piloto'},{id: 'D', desc: 'Suspensa'}];
  public static feriados: Feriados[]=[]
  public static idgrfin: number = 0;
  public static percmulta: number =0.00;
  public static percjuros: number = 0.00;
  public static sitpadrao: number = 0;
  public static endcobr: number = 0;
  public static menscobr:string = '';
  public static cnpjusuario:string = '';
  public static ieusuario:string = '';
  public static imusuario:string = '';
  public static rsusuario:string = '';
  public static idremessa: number = 0;
  public static idretorno: number = 0;
  public static idrpsnfs: number = 0;
  public static idpgtcom: number = 0;
  public static recjuros: number = 0;
  public static recmulta: number = 0;
  public static despdesc: number = 0;
  public static despcobr: number = 0;
  public static sitpago: number = 0;
  public static sitprot: number = 0;
  public static iddifrec: number = 0;
  public static iddadcsign: number = 0;
  public static iddadccargosign: number = 0;
  public static iddadcrgsign: number = 0;
  public static iddadccpfsign: number = 0;
  public static sitremessa: number = 0;
  public static sitremconf: number = 0;
  public static altpagto: number = 0;
  public static docpagto: number = 0;
  public static sitpendpagto: number = 0;
  public static regcompagto: number = 0;
  public static serierps: string ='';
  public static difmin: number = 0;
  public static aliqtrib: number = 0;
  public static fontetrib: string='';
  public static obsrps: string='';
}

export function AlteraTiposGeracao (parametros: Parametros[])
{
  let strtp = achaDescricao(5,parametros,'id_tppr','valor_par');
  let arrtp = strtp.split(',');
  if (arrtp.length>4)
  {
      ParametrosDiversos.tiposgeracao[0].desc=arrtp[0];
      ParametrosDiversos.tiposgeracao[1].desc=arrtp[1];
      ParametrosDiversos.tiposgeracao[2].desc=arrtp[4];
      ParametrosDiversos.tiposservico[0].desc=arrtp[0];
      ParametrosDiversos.tiposservico[1].desc=arrtp[1];
      ParametrosDiversos.tiposservico[2].desc=arrtp[2];
      ParametrosDiversos.tiposservico[3].desc=arrtp[3];
  }
}

export function AlteraPadroesRecebimento (parametros: Parametros[])
{
  ParametrosDiversos.recjuros=parseInt(achaDescricao(2,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.recmulta=parseInt(achaDescricao(3,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.idgrfin=parseInt(achaDescricao(4,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.percmulta=parseFloat(achaDescricao(6,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.percjuros=parseFloat(achaDescricao(7,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.sitpadrao=parseInt(achaDescricao(8,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.endcobr=parseInt(achaDescricao(9,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.cnpjusuario=achaDescricao(10,parametros,'id_tppr','valor_par');
  ParametrosDiversos.ieusuario=achaDescricao(11,parametros,'id_tppr','valor_par');
  ParametrosDiversos.imusuario=achaDescricao(12,parametros,'id_tppr','valor_par');
  ParametrosDiversos.rsusuario=achaDescricao(13,parametros,'id_tppr','valor_par');
  ParametrosDiversos.menscobr=achaDescricao(14,parametros,'id_tppr','valor_par');
  ParametrosDiversos.idremessa=parseInt(achaDescricao(15,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.idretorno=parseInt(achaDescricao(16,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.despdesc=parseInt(achaDescricao(17,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.despcobr=parseInt(achaDescricao(18,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.sitpago=parseInt(achaDescricao(19,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.sitprot=parseInt(achaDescricao(20,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.iddifrec=parseInt(achaDescricao(21,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.sitremessa=parseInt(achaDescricao(26,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.sitremconf=parseInt(achaDescricao(27,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.idrpsnfs=parseInt(achaDescricao(30,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.serierps=tamanhoExato(achaDescricao(31,parametros,'id_tppr','valor_par'),5,' ',false);
  ParametrosDiversos.difmin=parseFloat(achaDescricao(32,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.idpgtcom=parseInt(achaDescricao(33,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.aliqtrib=parseFloat(achaDescricao(43,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.fontetrib=achaDescricao(44,parametros,'id_tppr','valor_par');
  ParametrosDiversos.obsrps=achaDescricao(45,parametros,'id_tppr','valor_par');
}

export function AlteraPadroesPagamento (parametros: Parametros[])
{
  ParametrosDiversos.altpagto=parseInt(achaDescricao(28,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.docpagto=parseInt(achaDescricao(29,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.regcompagto=parseInt(achaDescricao(34,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.sitpendpagto=parseInt(achaDescricao(35,parametros,'id_tppr','valor_par'));
}

export function AlteraPadroesContrato (parametros: Parametros[])
{
  ParametrosDiversos.iddadcsign=parseInt(achaDescricao(22,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.iddadccargosign=parseInt(achaDescricao(23,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.iddadcrgsign=parseInt(achaDescricao(24,parametros,'id_tppr','valor_par'));
  ParametrosDiversos.iddadccpfsign=parseFloat(achaDescricao(25,parametros,'id_tppr','valor_par'));
}

export function RetornaReceitasDespesas(rcds:[],tipo: string):[]
{
    let ret:any=[];
    rcds.forEach((rd:any) => 
        {
            if (tipo==='A')
            {
                ret.push({id_rcta: rd.id_rcta, desc_rcta: rd.desc_grrc+'-'+rd.desc_sgrc+'-'+rd.desc_rcta});
            }
            else
            {
                ret.push({id_dsps: rd.id_dsps, desc_dsps: rd.desc_grds+'-'+rd.desc_sgds+'-'+rd.desc_dsps});
            }
        })
    return ret;
}


export abstract class Criptografia
{
  public static chave: string = "MasterOfficeRCKY";
  public static retira: string[] = ['+','%','\\']
  public static coloca: string[] = ['-','*','!']

  public static Encripta(dado: string): string
  {
    let retcrip: string;
    try 
    {
      retcrip=CryptoJS.AES.encrypt(dado, this.chave).toString();
      retcrip=trocaTodos(retcrip,this.retira, this.coloca);
    }
    catch(erro)
    {
      retcrip='';
    }
    return retcrip;
  }
  public static Decripta(dadocrip: string): string
  {
    let decrip: string;
    try 
    {
      decrip=trocaTodos(dadocrip,this.coloca, this.retira);
      decrip=CryptoJS.AES.decrypt(decrip, this.chave).toString(CryptoJS.enc.Utf8);
    }
    catch(erro)
    {
      decrip='';
    }
    return decrip;
  }
}

export function retPeriodoFiltro(vlr: string):{periodo: number, datini: Date | undefined, datfin: Date |undefined}
{
  let parms: {periodo: number, datini: Date | undefined, datfin: Date |undefined} = {periodo:0, datini: undefined, datfin: undefined};
  let valores: string[] = vlr.split('|');
  if(valores.length>0)
  {
      let perobt=nulltoNumber(parseInt(valores[0].trim()));
      if (perobt>0 && perobt<13)
      {
          let dtper: datas = RetornaPeriodos(perobt);
          parms.periodo=perobt;
          parms.datini=dtper.dtini;
          parms.datfin=dtper.dtfin;
      }
      else
      {
          if (valores.length>2)
          {
              parms.datini=MatrizParaData(valores[1].split(','));
              parms.datfin=MatrizParaData(valores[2].split(','));
          }
      }
  }
  return parms;
}

export function retPeriodoTitulo(periodo: datas): string
{
  let retorno: string= (periodo.dtini || periodo.dtfin ? RetornaStringData(periodo.dtini) + ' à ' + RetornaStringData(periodo.dtfin) : '')
  let hoje: Date = new Date();
  let amanha: Date = AdicionaDia(hoje);
  let ontem: Date = DiminuiDia(hoje);
  if (periodo.dtini && periodo.dtfin)
  {
    try 
    {
      if (periodo.dtini.getMonth()===periodo.dtfin.getMonth() && periodo.dtini.getFullYear()===periodo.dtfin.getFullYear() && periodo.dtini.getDate()===1 && periodo.dtfin.getDate()===Ultimodia(periodo.dtfin.getFullYear(), periodo.dtfin.getMonth()))
      {
        retorno=meses[periodo.dtini.getMonth()]+'/'+periodo.dtini.getFullYear().toString().trim().padStart(4,'0');
        retorno=retorno.substr(0,1).toUpperCase()+retorno.substr(1,retorno.length-1);
      }
      else if(periodo.dtini.getFullYear()===periodo.dtfin.getFullYear() && periodo.dtini.getDate()===1 && periodo.dtfin.getDate()===31 && periodo.dtini.getMonth()===0 && periodo.dtfin.getMonth()===11)
      {
        retorno=periodo.dtini.getFullYear().toString().trim().padStart(4,'0');
      }
      else if(periodo.dtini.getFullYear()===periodo.dtfin.getFullYear() && periodo.dtini.getMonth()===periodo.dtfin.getMonth() && periodo.dtini.getDate()===periodo.dtfin.getDate() && periodo.dtini.getFullYear()===hoje.getFullYear() && periodo.dtini.getMonth()===hoje.getMonth() && periodo.dtini.getDate()===hoje.getDate())
      {
        retorno='Hoje';
      }
      else if(periodo.dtini.getFullYear()===periodo.dtfin.getFullYear() && periodo.dtini.getMonth()===periodo.dtfin.getMonth() && periodo.dtini.getDate()===periodo.dtfin.getDate() && periodo.dtini.getFullYear()===ontem.getFullYear() && periodo.dtini.getMonth()===ontem.getMonth() && periodo.dtini.getDate()===ontem.getDate())
      {
        retorno='Ontem';
      }
      else if(periodo.dtini.getFullYear()===periodo.dtfin.getFullYear() && periodo.dtini.getMonth()===periodo.dtfin.getMonth() && periodo.dtini.getDate()===periodo.dtfin.getDate() && periodo.dtini.getFullYear()===amanha.getFullYear() && periodo.dtini.getMonth()===amanha.getMonth() && periodo.dtini.getDate()===amanha.getDate())
      {
        retorno='Amanhã';
      }
    } 
    catch (error) 
    {
      retorno=RetornaStringData(periodo.dtini) + ' à ' + RetornaStringData(periodo.dtfin);
    }
  }
  return retorno;
}

export const posicoesdash = [
  {id_pos: 'A', desc_pos: 'Quadro 1 - 1'},
  {id_pos: 'B', desc_pos: 'Quadro 1 - 2'},
  {id_pos: 'C', desc_pos: 'Quadro 1 - 3'},
  {id_pos: 'D', desc_pos: 'Quadro 1 - 4'},
  {id_pos: 'E', desc_pos: 'Quadro 2 - 1'},
  {id_pos: 'F', desc_pos: 'Quadro 2 - 2'},
  {id_pos: 'G', desc_pos: 'Quadro 2 - 3'},
  {id_pos: 'H', desc_pos: 'Quadro 2 - 4'},
  {id_pos: 'I', desc_pos: 'Quadro 3 - 1'},
  {id_pos: 'J', desc_pos: 'Quadro 3 - 2'},
  {id_pos: 'K', desc_pos: 'Quadro 3 - 3'},
  {id_pos: 'L', desc_pos: 'Quadro 3 - 4'},
  {id_pos: 'M', desc_pos: 'Quadro 4 - 1'},
  {id_pos: 'N', desc_pos: 'Quadro 4 - 2'},
  {id_pos: 'O', desc_pos: 'Quadro 4 - 3'},
  {id_pos: 'P', desc_pos: 'Quadro 4 - 4'}
]

export const retTamanhos = (tamdash: string, posicao: string, vazios: number[]) =>
{
  let tamret: {id_tam: string, desc_pos: string}[] = [];
  let posnumber: number = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.indexOf(posicao) + 1;
  if(posnumber)
  {
    tamret=tamanhosdash.filter(tmn => tamdash.indexOf(tmn.id_tam)!==-1);
    posnumber=posnumber-((Math.ceil(posnumber/4)-1)*4)
    let naocabe: string [] = [];
    switch (posnumber)
    {
      case 1:
      {
        if (!vazios.some(vazio => vazio===2))
        {
          naocabe=naocabe.concat(['B', 'D'])
        }
        if (!vazios.some(vazio => vazio===3))
        {
          naocabe=naocabe.concat(['C', 'D'])
        }
        if (!vazios.some(vazio => vazio===4))
        {
          naocabe=naocabe.concat(['D'])
        }
        break;
      }
      case 2:
      {
        naocabe=naocabe.concat(['B','D']);
        if (!vazios.some(vazio => vazio===3))
        {
          naocabe=naocabe.concat(['C'])
        }
        break;
      }
      case 3:
      {
        naocabe=naocabe.concat(['C','D']);
        if (!vazios.some(vazio => vazio===4))
        {
          naocabe=naocabe.concat(['B'])
        }
        break;
      }
      case 4:
      {
        naocabe=naocabe.concat(['B','C','D']);
        break;
      }
    }
    tamret=tamret.filter(tmn => !naocabe.some(ncb => ncb===tmn.id_tam));
  }
  return tamret;
}

export type DashProps = 
{
  dadosacesso: RetornoAcesso,
  dashoper: Dashboard_Operador,
  quadro: {numero: number, posicao: number, vazios: number[]},
  edita: (dashoper: Dashboard_Operador, vazios: number[], atudash: (atudash: Dashboard_Operador) => void, exclusao:boolean) => void,
  altura: number
}

export const tamanhosdash = [
  {id_tam: 'A', desc_pos: '1 linha x 1 coluna'},
  {id_tam: 'B', desc_pos: '1 linha x 2 colunas'},
  {id_tam: 'C', desc_pos: '2 linhas x 1 coluna'},
  {id_tam: 'D', desc_pos: '2 linhas X 2 colunas'}
]

export const altbase: number = 815;

export const MatrizCores = [
  {cor: '#1E90FF'},
  {cor: '#00FA9A'},
  {cor: '#FF4500'},
  {cor: '#FFD700'},
  {cor: '#FFDAB9'},
  {cor: '#7FFFD4'},
  {cor: '#FF69B4'},
  {cor: '#DA70D6'},
  {cor: '#228B22'},
  {cor: '#87CEFA'},
  {cor: '#FA8072'},
  {cor: '#DB7093'},
  {cor: '#FFA500'},
  {cor: '#DEB887'},
  {cor: '#87CEEB'},
  {cor: '#00FF7F'},
  {cor: '#FF6A6A'},
  {cor: '#7FFF00'},
  {cor: '#00BFFF'},
  {cor: '#FFFACD'},
  {cor: '#FF7F50'},
  {cor: '#00FFFF'},
  {cor: '#EEEE00'},
  {cor: '#EE5C42'},
  {cor: '#FFA54F'},
  {cor: '#4682B4'},
  {cor: '#00FA9A'},
  {cor: '#FFEFD5'},
  {cor: '#FF8247'},
  {cor: '#8B658B'},
]

export const retornaCor = (indice: number): string =>
{
  let indret:number=(indice < 0 ? 0 : indice);
  while (indret>=MatrizCores.length)
  {
    indret=indret-MatrizCores.length;
  }
  return MatrizCores[indret].cor;
} 

export const ChaveApiGoogle: string = 'AIzaSyALEv-QvSbLlmHGy1B-uqwuSS4z8vfvh9E';

export type CenterMap = {lat: number, lng: number}

export const LocalizacaoRCKY: CenterMap = {lat: -23.5283997, lng: -46.5276766};

export function RegistraFontes()
{
  const fontes: {family: string, src: string}[] = [
    {family: 'Oswald', src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'},
    {family: 'Arial', src: 'Fontes/arial.ttf'},
    {family: 'Arial-Bold', src: 'Fontes/arialbd.ttf'}
  ]
  
  fontes.forEach(font => Font.register(font));
}

export type DadosConexao = 
{
  ok: boolean,
  erro: string,
  catalogo: string,
  homologa: boolean
}

export type RecebimentosObtidos =
{
  id_rcbt: number,
  idinterno_rcbt: string,
  id_strc: number,
  desc_strc: string,
  comportamento_strc: string,
  id_cta: number,
  carteira_rcbt: string,
  numban_rcbt: string
  id_cli: number,
  nome_pessoa: string,
  fantasia_pessoa: string,
  id_rede: number,
  nome_rede: string,
  loja_cli: number,
  valor_rcbt: number,
  jurosrec_rcbt: number,
  multarec_rcbt: number,
  descapl_rcbt: number,
  despesas_rcbt: number,
  liquido: number,
  vencto_rcbt: Date,
  pagto_rcbt?: Date,
  atraso: number,
  detalhe: 
  {
    id_dtrc: number,
    id_serv: number,
    valor_dtrc: number
  }[]
}

export type PagamentosObtidos =
{
  id_pgto: number,
  id_stpg: number,
  desc_stpg: string,
  comportamento_stpg: string,
  id_cta: number,
  id_forn: number,
  nome_pessoa: string,
  fantasia_pessoa: string,
  valor_pgto: number,
  juros_pgto: number,
  multa_pgto: number,
  desconto_pgto: number,
  datadesc_pgto?: Date,
  vencto_pgto: Date,
  pagto_pgto?: Date
}
