import { RouteComponentProps, withRouter } from 'react-router';
import { RetornoAcesso } from '../Modelos/ObjetosDiversos';
import { Roteador } from '../Servicos/Roteador';
import { Resposta, TrataResposta, datas, RetornaPeriodos, ParametrosDiversos, AlteraTiposGeracao, RetornaDataAAAAMMDD, RetornaStringData, AlteraPadroesRecebimento, Iheaders, DataSql, formataDecimal, DifDias, BotaoDialogo, achavalor, tamanhoExato, RetiraAcentos, formatarCampo, nulltoNumber, ConverteStringData } from '../Servicos/Utilidades';
import ApiService from '../Servicos/ApiService';
import { Recebimentos } from '../Modelos/Recebimentos';
import BarraLateral from '../Componentes/Barralateral';
import Toast from '../Componentes/Toast';
import Progresso from '../Componentes/Progresso';
import ProgressoEtiqueta from '../Componentes/ProgressoEtiqueta';
import { makeStyles, Theme, createStyles, Grid, Button, Typography, Box, FormControlLabel, Switch } from '@material-ui/core';
import React from 'react';
import SaveIcon from '@material-ui/icons/Save';
import PageviewIcon from '@material-ui/icons/Pageview';
import { Component } from 'react';
import Selecao from '../Componentes/Selecao';
import { Clientes } from '../Modelos/Clientes';
import { Parametros } from '../Modelos/Parametros';
import { Redes } from '../Modelos/Redes';
import { Servicos } from '../Modelos/Servicos';
import Periodo from '../Componentes/Periodo';
import FileSaver from 'file-saver';
import TabelaBase from '../Componentes/TabelaBase';
import { Ocorrencias_Recebimento } from '../Modelos/Ocorrencias_Recebimento';
import CampoData from '../Componentes/CampoData';
import { Pessoa_Documentos } from '../Modelos/Pessoa_Documentos';
import AlertDialog from '../Componentes/Dialogo';
import CampoTexto from '../Componentes/CampoTexto';

const srv = new ApiService();

const useStyles = makeStyles((theme: Theme) => createStyles({
    griddados: {
      width: "100%",
      margin: "0 0 0 0"
    },
    gridtab: {
      width: "50%",
      margin: "0 0 0 0"
    },
    divgrid: {
        width: '90%',
        margin: '5% 7% 0'
    },
    divtabs: {
        width: '90%',
        margin: '0% 7% 0'
    },
    boxpanel: {
        width: '104%',
        margin: '0% 0% 0% -2%',
        padding: '0 0 0 0'
    },
    textoform: {
        margin: "0% 0%",
        width: "100%",
        background: 'white'
    },
    botao: {
        margin: '10px 1% 0 1%',
        width: '98%'
      },
    tab: {
        minWidth: "50%",
        width: "50%"
    },
    gridcampos: {
        width: "80%",
        margin: "0 0 0 0"
    },
    gridfoto: {
        width: "20%",
        margin: "0 0 0 0"
    },
}));

let filtvenc: datas | undefined = RetornaPeriodos(7);
let filtpgto: datas | undefined = RetornaPeriodos(7);
let periodo: datas = {dtini: undefined, dtfin: undefined};
let tabelarec: Tabrec[] = [];
let recret: {id_rcbt: number, idinterno_rcbt: string, vencto_rcbt: Date, pagto_rcbt: Date, valor_rcbt: number, id_cli: number, nome_pessoa: string, fantasia_pessoa: string, nome_rede: string, loja_cli: number, detalhes: {id_dtrc: number, id_serv: number, valor_dtrc: number}[]}[] = [];
let selecionadas: number[] = [];
let rps: string[] = [];
let arqrps: string = '';
let hoje=new Date();
let dtcomp: Date | undefined =new Date;
let totreg = 0;
let interv = 0;
let valtot: number = 0;
let titulodialogo: string;
let botoesdialogo: BotaoDialogo[] = [];
let textodialogo: string[] = [];

type PrincipalProps=
{
    dadosobt: RetornoAcesso
    redes: Redes[];
    servicos: Servicos[];
    ultrpsobt: number;
    mensagem: (mensagem: string, severidade: string, duracao:number, retorna?:boolean) => void
}

type Tabrec=
{
    indice: number,
    id: number,
    id_interno: string,
    vencto_rcbt: Date,
    pagto_rcbt: Date,
    valor_rcbt: number,
    id_cli: number,
    nome_pessoa: string,
    fantasia_pessoa: string,
    nome_rede: string,
    loja_cli: number,
    cliente: Clientes,
    detalhes: {id_dtrc: number, id_serv: number, valor_dtrc: number}[],
    flagedicao: string
}

type Cliobt =
{
    id_cli: number, 
    tipodoc: string, 
    cpfcnpj: string, 
    id_pessoa: number, 
    nome_pessoa: string, 
    fantasia_pessoa: string,
    email: string,
    enderecos: {cep_end: string, logr_end: string, numero_end: string, compl_end: string, bairro_end: string, nome_cid: string, sigla_uf: string}[]
}

const PrincipalGeracaoRPS = ({dadosobt, redes, servicos, ultrpsobt, mensagem }: PrincipalProps) =>
{
    const classes = useStyles();
    const [rede,setrede]=React.useState(0);
    const [datarec,setdatarec]=React.useState<Date | undefined>(undefined);
    const [progresso,setaprogresso]=React.useState({processo: false, percentual: 0});
    const [cliproc,setcliproc]=React.useState('');
    const [operexec,setoperexec]=React.useState('A');
    const [dialogo, setDialog] = React.useState(false);
    const [ultrps, setNumrps] = React.useState(ultrpsobt);
    const [altrps, setAltrps] = React.useState(false);

    const srv = new ApiService();

    const AtualizaFiltro = (campo:string, valor: any) =>
    {
        if(campo==='Vencimento')
        {
            filtvenc={dtini: valor.dtini, dtfin: valor.dtfin}
        }
        else if(campo==='Pagamento')
        {
            filtpgto={dtini: valor.dtini, dtfin: valor.dtfin}
        }
    }

    const AlteraSelecionadas = (sel: number[]) =>
    {
        selecionadas=sel;
    }

    const fechaDialogo = (retorno: string) => 
    {
      setDialog(false);
      if (retorno==='A')
      {
        criaArquivo();
      }
    }
  
    const obtemLancamentos = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => 
    {
        if (filtvenc?.dtini && filtvenc?.dtfin && filtvenc.dtfin>=filtvenc.dtini)
        {
            if (filtpgto?.dtini && filtpgto?.dtfin && filtpgto.dtfin>=filtpgto.dtini)
            {
                srv.Get('Recebimentos/Detalhes/5,6,8' + (rede>0 ? ',9,10/' : ',10,12/') + RetornaDataAAAAMMDD(filtvenc?.dtini)+','+RetornaDataAAAAMMDD(filtpgto?.dtini)+',' + ParametrosDiversos.sitpago.toString().trim() + (rede>0 ? ','+rede.toString().trim() : '')+', ,0/'+ RetornaDataAAAAMMDD(filtvenc?.dtfin)+','+RetornaDataAAAAMMDD(filtpgto?.dtfin)+',' + ParametrosDiversos.sitpago.toString().trim()+ (rede>0 ? ',' + rede.toString().trim() : '') + ',0,0/A', '', '', dadosobt.objac.token, false)
                .then((ddret: any) => 
                {
                    let dadosret: Resposta = TrataResposta(ddret);
                    if (dadosret.ok)
                    {
                        totreg = dadosret.retorno.length;
                        if (totreg>0)
                        {
                            valtot=0;
                            recret=[];
                            dadosret.retorno.forEach((rc:any) => recret.push(rc));
                            tabelarec=[];
                            interv = Math.max(Math.ceil(totreg/100),1);
                            setaprogresso({processo:true, percentual: 0});
                            setoperexec('B');
                            IniciaPreenchimentoTabela();
                        }
                        else
                        {
                            mensagem('Não há registros para os filtros informados','warning',5000, false);
                        }
                    }
                    else
                    {
                        mensagem('Erro: Erro obtendo dados de recebimentos (' + dadosret.erro + ')','error',5000, false);
                    }
                })
                .catch(Error => mensagem('Erro obtendo dados de recebimentos acessando a API (' + Error.name + ' - ' + Error.message + ')','error',5000, false));
                }
            else
            {
                mensagem('Atenção: verifque o período de pagamento selecionado', 'error', 6000, false);
            }
        }
        else
        {
            mensagem('Atenção: verifque o período de vencimento selecionado', 'error', 6000, false);
        }
    }

    const IniciaPreenchimentoTabela = () =>
    {
        PreencheTabela(0);
    }

    const PreencheTabela = (indrec: number) =>
    {
        if (indrec % interv===0 || indrec===totreg-1)
        {
            setaprogresso({processo: true, percentual: Math.trunc(((indrec+1)/totreg)*100)});
        }
        setcliproc(recret[indrec].id_rcbt.toString()+' - '+recret[indrec].idinterno_rcbt+' - '+recret[indrec].loja_cli.toString()+' - '+recret[indrec].fantasia_pessoa+' - '+RetornaStringData(recret[indrec].vencto_rcbt)+' - '+formataDecimal(recret[indrec].valor_rcbt.toString()));
        if (recret[indrec].detalhes.some(regdet => nulltoNumber(regdet.id_serv) != 0))
        {
            srv.Get('Clientes/'+(recret[indrec].id_cli.toString().trim()), '', '', dadosobt.objac.token, false)
            .then((ddret: any) => 
            {
                let dadosret: Resposta = TrataResposta(ddret);
                if (dadosret.ok)
                {
                    tabelarec.push(
                    {
                        indice: indrec,
                        id: recret[indrec].id_rcbt,
                        id_interno: recret[indrec].idinterno_rcbt,
                        vencto_rcbt: recret[indrec].vencto_rcbt,
                        pagto_rcbt: recret[indrec].pagto_rcbt,
                        valor_rcbt: recret[indrec].valor_rcbt,
                        id_cli: recret[indrec].id_cli,
                        nome_pessoa: recret[indrec].nome_pessoa,
                        fantasia_pessoa: recret[indrec].fantasia_pessoa,
                        nome_rede: recret[indrec].nome_rede,
                        loja_cli: recret[indrec].loja_cli,
                        cliente: dadosret.retorno,
                        detalhes: recret[indrec].detalhes,
                        flagedicao: ''
                    
                    });
                    tabelarec[tabelarec.length-1].detalhes.forEach(dt => valtot=valtot+dt.valor_dtrc);
                    ChamaProximaTabela(indrec+1);
                }
                else
                {
                    setoperexec('A');
                    mensagem('Erro obtendo dados de clientes (' + dadosret.erro + ')','error',5000, false);
                }
            })
            .catch(Error => {setoperexec('A'); mensagem('Erro obtendo dados de clientes acessando a API (' + Error.name + ' - ' + Error.message + ')','error',5000, false)});
        }
        else
        {
            totreg=totreg-1;
            ChamaProximaTabela(indrec+1);
        }
    }

    const ChamaProximaTabela = (indrec: number) =>
    {
        if (indrec<recret.length)
        {
            PreencheTabela(indrec);
        }
        else
        {
            setoperexec('C');
            setaprogresso({processo: false, percentual:0});
            setcliproc('Registros: '+totreg.toString()+' Valores: '+formatarCampo(valtot.toString(),'decimal',true));
        }
    }

    const IniciaGravacao = () =>
    {
        if (selecionadas.length>0 && tabelarec.length>0)
        {
            hoje=new Date();
            if (datarec)
            {
                criaArquivo();
            }
            else
            {
                periodo = {dtini: undefined, dtfin: undefined};
                tabelarec.forEach(reg => 
                {
                    if (selecionadas.some(sel => sel===reg.id))
                    {
                        dtcomp = (typeof reg.pagto_rcbt==='string' ? ConverteStringData(reg.pagto_rcbt) : reg.pagto_rcbt);

                        if (!periodo.dtini || (dtcomp && dtcomp<periodo.dtini))
                        {
                            periodo.dtini=dtcomp;
                        }
                        if (!periodo.dtfin || (dtcomp && dtcomp>periodo.dtfin))
                        {
                            periodo.dtfin=dtcomp;
                        }
                    }
                })
                if (periodo.dtini && periodo.dtfin)
                {
                    textodialogo=[]
                    let difdias1: number = DifDias(periodo.dtini, hoje, false, true);
                    let difdias2: number = DifDias(periodo.dtfin, hoje, false, true);
                    if (difdias1<0 || difdias2<0)
                    {
                        textodialogo.push('Há datas de pagamento posteriores ao dia de hoje')
                    }
                    if (difdias1>10)
                    {
                        textodialogo.push('Há datas de pagamento há mais de dez dias');
                        textodialogo.push('(o RPS deve ser convertido para NFS em até dez dias)');
                    }
                    else
                    {
                        if (hoje.getDate()>5 && hoje.getMonth()!==periodo.dtini.getMonth())
                        {
                            textodialogo.push('Há pagamentos pertencentes à outro mês');
                            textodialogo.push('(o RPS deve ser convertido para NFS até o dia cinco do mês subsequente ao da sua emissão)');
                        }
                    }
                    if(textodialogo.length>0)
                    {
                        botoesdialogo=[{descricao: 'Confirma', retorno: 'A'},{descricao: 'Cancela', retorno: 'B'}]
                        titulodialogo='Há inconsistências para a geração do arquivo de conversão de RPS em NFS'
                        textodialogo.push('Confirma a operação?');
                        setDialog(true)
                    }
                    else
                    {
                        criaArquivo();
                    }
                }
                else
                {
                    mensagem('Período não identificado para geração do arquivo de conversão de RPS em NFS','warning',5000, false)
                }
            }
        }
        else
        {
            mensagem('Nenhum registro selecionado para geração de arquivo de conversão RPS -> NFS','warning',5000, false)
        }
    }

    const criaArquivo = () =>
    {
        setoperexec('D');
        setaprogresso({processo: true, percentual:0});
        valtot=0;
        rps=[];
        arqrps="RPSNFS"+RetornaDataAAAAMMDD(hoje)+(hoje.getHours().toString().padEnd(2,'0'))+(hoje.getMinutes().toString().padEnd(2,'0'))
        rps.push('1001'+tamanhoExato(ParametrosDiversos.imusuario,8,'0')+RetornaDataAAAAMMDD(datarec ? datarec : periodo.dtini)+RetornaDataAAAAMMDD(datarec ? datarec : periodo.dtfin)+'\u000D\u000A')
        ObtemCliente(0);
    }

    const ObtemCliente = (indrec: number) =>
    {
        setcliproc((indrec+1).toString()+'/'+totreg.toString()+' - '+tabelarec[indrec].id_cli.toString()+' - '+RetornaStringData(tabelarec[indrec].vencto_rcbt)+' - '+formataDecimal(tabelarec[indrec].valor_rcbt.toString()));
        if (indrec % interv===0 || indrec===totreg-1)
        {
            setaprogresso({processo: true, percentual: Math.trunc(((indrec+1)/totreg)*100)});
        }
        if (selecionadas.some(sel => sel===tabelarec[indrec].id))
        {
            let cliente: Clientes = tabelarec[indrec].cliente;
            let achadoc: number = cliente.pessoa.documentos.findIndex((dct: Pessoa_Documentos) => dct.id_doc===1 || dct.id_doc===2);
            let cliobt: Cliobt = 
            {
                id_cli: cliente.id_cli,
                tipodoc: (achadoc>=0 ? (cliente.pessoa.documentos[achadoc].id_doc===1 ? '2' : '1') : '3'),
                cpfcnpj: tamanhoExato(achadoc>=0 ? cliente.pessoa.documentos[achadoc].valor_psdc : '',14,'0'),
                id_pessoa: cliente.pessoa.id_pessoa,
                nome_pessoa: cliente.pessoa.nome_pessoa,
                fantasia_pessoa: cliente.pessoa.fantasia_pessoa,
                email: cliente.pessoa.emails.length>0 ? cliente.pessoa.emails[0].endereco_email : '',
                enderecos: [{cep_end: '', logr_end: '', numero_end: '', compl_end: '', bairro_end: '', nome_cid: '', sigla_uf: ''}]
            }
            cliente.pessoa.enderecos.forEach((end, idx) => 
            {
                if (idx===0 || end.padrao_enps==='A')
                {
                    cliobt.enderecos[0]=
                    {
                        cep_end: end.endereco.cep_end,
                        logr_end: end.endereco.logr_end,
                        numero_end: end.numero_end,
                        compl_end: end.compl_end,
                        bairro_end: end.endereco.bairro_end,
                        nome_cid: end.endereco.cidade.nome_cid,
                        sigla_uf:end.endereco.cidade.uf.sigla_uf,
                    }
                }
            });
            GravaRecebimento(indrec, cliobt);
        }
        else
        {
            ChamaProximo(indrec);
        }
    }

    const GravaRecebimento = (indrec: number, cliobt: Cliobt) =>
    {
        let srvprs: {codserv: string, descserv: string, aliquota: string, valor: number, id_dtrc: number[]}[] = [];
        let indserv: number;
        let indsrv: number;
        let codserv: string;
        tabelarec[indrec].detalhes.forEach((dt) =>
        {
            indserv=achavalor(dt.id_serv, servicos, 'id_serv','*index*',0) as number;
            if (indserv>=0)
            {
                codserv=tamanhoExato(servicos[indserv].codnf_serv,5,'0');
                indsrv=achavalor(codserv,srvprs,'codserv','*index*',0) as number;
                if (indsrv<0)
                {
                    srvprs.push({codserv: codserv, descserv: servicos[indserv].desc_serv.trim().padEnd(1,'.'), aliquota: tamanhoExato((servicos[indserv].aliquota_serv*100).toString(),4,'0'), valor: dt.valor_dtrc, id_dtrc: [dt.id_dtrc]});
                }
                else
                {
                    srvprs[indsrv].valor=srvprs[indsrv].valor+dt.valor_dtrc;
                    srvprs[indsrv].id_dtrc.push(dt.id_dtrc);
                }
            }
        });

        if (srvprs.length>0)
        {
            srv.Get('Recebimentos/'+(tabelarec[indrec].id.toString().trim()), '', '', dadosobt.objac.token, false)
            .then((ddret: any) => 
            {
                let dadosret: Resposta = TrataResposta(ddret);
                if (dadosret.ok)
                {
                    let achadt:number;
                    let recebimento: Recebimentos = dadosret.retorno;
                    let rpsgrv: string = '';
                    let ocor=new Ocorrencias_Recebimento()
                    ocor.id_tpor=ParametrosDiversos.idrpsnfs;
                    ocor.id_rcbt=tabelarec[indrec].id;
                    ocor.data_ocrc=DataSql(hoje);
                    ocor.id_oper=dadosobt.objac.id_oper;
                    ocor.tipoarq_ocrc='3';
                    ocor.regbanco_ocrc=(rps.length+1);
                    ocor.nomearq_ocrc=arqrps;
                    ocor.obs_ocrc='RPS: '
                    srvprs.forEach((srpr,idx) =>
                    {
                        rpsgrv=(ultrps+(rps.length-1)).toString();
                        valtot=valtot+srpr.valor;
                        rps.push('2RPS  '+ParametrosDiversos.serierps+tamanhoExato(rpsgrv,12,'0')+RetornaDataAAAAMMDD(datarec ? datarec : tabelarec[indrec].pagto_rcbt)+'T'+tamanhoExato(Math.round(srpr.valor*100).toString(),15,'0')+tamanhoExato('0',15,'0')+srpr.codserv+srpr.aliquota+'2'+cliobt.tipodoc+cliobt.cpfcnpj+tamanhoExato('0',20,'0')+RetiraAcentos(tamanhoExato(cliobt.nome_pessoa,75,' ',false)+'   '+tamanhoExato(cliobt.enderecos[0].logr_end,50,' ',false)+tamanhoExato(cliobt.enderecos[0].numero_end,10,' ',false)+tamanhoExato(cliobt.enderecos[0].compl_end,30,' ',false)+tamanhoExato(cliobt.enderecos[0].bairro_end,30,' ',false)+tamanhoExato(cliobt.enderecos[0].nome_cid,50,' ',false)+tamanhoExato(cliobt.enderecos[0].sigla_uf,2,' ',false)+tamanhoExato(cliobt.enderecos[0].cep_end,8,'0')+tamanhoExato(cliobt.email,75,' ',false)+srpr.descserv)+'|Vencimento: '+RetornaStringData(tabelarec[indrec].vencto_rcbt,false)+' Pagamento: '+RetornaStringData(tabelarec[indrec].pagto_rcbt,false)+'|Valor aproximado dos tributos: R$ '+formatarCampo((Math.round(srpr.valor*ParametrosDiversos.aliqtrib)/100).toString(),'decimal',true)+' ('+formatarCampo(ParametrosDiversos.aliqtrib.toString(),'decimal',true)+'%) Fonte: '+ParametrosDiversos.fontetrib+'||'+ParametrosDiversos.obsrps+'\u000D\u000A');
                        srpr.id_dtrc.forEach(dtrc =>
                        {
                            achadt=achavalor(dtrc,recebimento.detalhes,'id_dtrc','*index*',0) as number;
                            if (achadt>=0)
                            {
                                recebimento.detalhes[achadt].rps_dtrc=rpsgrv;
                            }
                        });
                        ocor.obs_ocrc = ocor.obs_ocrc + (idx===0 ? '' : ' / ') + rpsgrv;
                    })
                    recebimento.ocorrencias.push(ocor);
                    recebimento.nfs_rcbt=(srvprs.length>1 ? 'Diversos' : rpsgrv);
                    recebimento.rps_rcbt=(srvprs.length>1 ? 'Diversos' : rpsgrv);
                    recebimento.id_oper=dadosobt.objac.id_oper;
                    srv.Post('Recebimentos/','',JSON.stringify(recebimento),dadosobt.objac.token,false)
                    .then((ddret: any) => 
                    {
                        let dadosret: Resposta = TrataResposta(ddret);
                        if (!dadosret.ok)
                        {
                            mensagem('Erro gravando recebimento (' + dadosret.erro + ')','error',6000);
                            setoperexec('A');
                        }
                        else
                        {
                            ChamaProximo(indrec);
                        }
                    })
                    .catch(Error => { mensagem('Erro gravando recebimento acessando a API (' + Error.name + ' - ' + Error.message + ')', 'error',6000); setoperexec('A'); });
                }
                else
                {
                    mensagem('Erro obtendo dados de recebimento (' + dadosret.erro + ')','error',6000);
                    setoperexec('A');
                }
            })
            .catch(Error => { mensagem('Erro obtendo dados de recebimento acessando a API (' + Error.name + ' - ' + Error.message + ')','error',5000, false); setoperexec('A');});
        }
        else
        {
            ChamaProximo(indrec);
        }
    }

    const ChamaProximo = (indrec: number) =>
    {
        if (tabelarec.length>(indrec+1))
        {
            ObtemCliente(indrec+1);
        }
        else
        {
            rps.push('9'+tamanhoExato((rps.length-1).toString(),7,'0')+tamanhoExato((valtot*100).toFixed(0).toString(),15,'0')+tamanhoExato('0',15,'0')+'\u000D\u000A');
            downloadarquivo();
            mensagem('Final de processamento','info',10000,true);
        }
    }

    const hdtabrec: Iheaders[] = 
    [
        { titulo: 'Id', alinhamento:'right', coluna: 'id', mascara: '' }, 
        { titulo: 'Id interno', alinhamento: 'left', coluna: 'id_interno', mascara: '' },
        { titulo: 'Vencimento', alinhamento: 'center', coluna: 'vencto_rcbt', mascara: 'data' },
        { titulo: 'Pagamento', alinhamento: 'center', coluna: 'pagto_rcbt', mascara: 'data' },
        { titulo: 'Valor', alinhamento: 'right', coluna: 'valor_rcbt', mascara: 'decimal' },
        { titulo: 'Id cliente', alinhamento: 'right', coluna: 'id_cli', mascara: '' }, 
        { titulo: 'Cliente', alinhamento: 'left', coluna: 'nome_pessoa', mascara: '' }, 
        { titulo: 'Fantasia', alinhamento: 'left', coluna: 'fantasia_pessoa', mascara: '' }, 
        { titulo: 'Rede', alinhamento: 'left', coluna: 'nome_rede', mascara: '' },
        { titulo: 'Loja', alinhamento: 'right', coluna: 'loja_cli', mascara: '' }, 
        { titulo: '#', alinhamento: 'right', coluna: 'flagedicao', mascara: '' }
    ]
 
    const downloadarquivo = () =>
    {
        var blob = new Blob(rps, {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob,arqrps);
    }

    const onChangeRPS = (param1: any | React.ChangeEvent<{name?: string | undefined; value: unknown;}> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, param2: string | React.ReactNode | null | undefined | number | Date, campo?: string | undefined) => 
    {
        if (campo)
        {
            if (campo==='id_rede')
            {
                let valor:number;
                valor = typeof param1.target.value === 'string' ? parseInt(param1.target.value) : typeof param1.target.value === 'number' ? param1.target.value : 0; 
                setrede(valor);
            }
            else if(campo==='datarec')
            {
                setdatarec(param1);
            }
            else if(campo==='ultrps')
            {
                setNumrps(param2 as number);
            }
        }
        return null;
    }

    return (
        <div>
            <div className={classes.divgrid}>
                <AlertDialog aberta={dialogo} fecha={fechaDialogo} titulo={titulodialogo} texto={textodialogo} botoes={botoesdialogo}/>
                <Grid container spacing={1} className={classes.griddados}>
                    <Grid item xs={2}>
                        <Selecao valor={rede}  nome='id_rede' label='Rede' required={false} habilitado={true} onchange={onChangeRPS} conteudo={redes} nulo={[0,'Indefinida']} classe={classes.textoform} />
                    </Grid>
                    <Grid item xs={2}>
                        <CampoData valor={datarec} id='datarec' label='Data do recibo' placeholder='Data do recibo' classe={classes.textoform} required={false} habilitado={true} onchange={onChangeRPS} limpadata={() => {setdatarec(undefined)}}/>
                    </Grid>
                    <Grid item xs={4}>
                        <Periodo capper='Vencimento' camporet='Vencimento' atualiza={AtualizaFiltro} perpad={7} dataspad={filtvenc ? filtvenc : {dtini: undefined, dtfin: undefined}} tipoper='ABC' />
                    </Grid>
                    <Grid item xs={4}>
                        <Periodo capper='Pagamento' camporet='Pagamento' atualiza={AtualizaFiltro} perpad={7} dataspad={filtpgto ? filtpgto : {dtini: undefined, dtfin: undefined}} tipoper='AB' />
                    </Grid>
                    <Grid item xs={1}>
                        <FormControlLabel style={{width: '100%', margin: '7px 0 0 0'}}
                            control={<Switch color={"primary"} disabled={false} checked={altrps} onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {setAltrps(checked)}} />}
                            label="Altera RPS"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <CampoTexto valor={ultrps}  id='ultrps' label='Nº RPS' placeholder='Número do RPS' classe={classes.textoform} tipo='number' required={true} habilitado={altrps} onchange={onChangeRPS} erro={ultrps<1}/>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" disabled={'BD'.indexOf(operexec)!==-1} className={classes.botao} onClick={(event) => obtemLancamentos(event)} endIcon={<PageviewIcon/>}>Obtem Lançamentos</Button>
                    </Grid>
                    <Grid item xs={2}>
                        {progresso.processo || operexec==='C'?
                            <Box minWidth={35} margin='20px 0 0 10px'>
                                <Typography color='primary'>{operexec==='B' ? 'Obtendo lançamentos' : operexec==='C'? 'Totalizadores' : operexec==='D' ? 'Gerando arquivo RPS->NFS' : ''}</Typography>
                            </Box>
                        :
                            null
                        }
                    </Grid>
                    <Grid item xs={2}>
                        {progresso.processo || operexec==='C'?
                            <Box minWidth={35} margin='20px 0 0 10px'>
                                <Typography color='primary'>{cliproc}</Typography>
                            </Box>
                        :
                            null
                        }
                    </Grid>
                    <Grid item xs={1}>
                        {progresso.processo ?
                            <ProgressoEtiqueta value={progresso.percentual} tipo='B' />
                        :
                            null
                        }
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="contained" color="primary" disabled={operexec!=='C'} className={classes.botao} onClick={IniciaGravacao} endIcon={<SaveIcon/>}>{'Gera Arquivo RPS -> NFS'}</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TabelaBase linhasoriginais={'CD'.indexOf(operexec)!==-1 ? tabelarec : []} cab={hdtabrec} selecao={true} edicao={false} adicao={false} habfiltro={false} onClickEdicao={(val1, val2) => {}} titulo={'Recebimentos'} full={true} linhasiniciais={15} exclusao={false} auxedit={1} auxexclui={2} onSelect={AlteraSelecionadas} />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}  

type PathParamsType =
{

}

type PropsType = RouteComponentProps<PathParamsType>;

interface IState {
  dadoobtido: boolean,
  openToast: boolean,
  severidadeToast: string,
  mensagemToast: string,
  duracaoToast: number,
  acessosok: boolean,
}

class GeracaoRPS extends Component<PropsType, IState>
{
    private _dadosobt: RetornoAcesso;
    private _roteador: Roteador;
    private _dadoobtido: boolean;
    private _acessos: number;
    private _redes: Redes[];
    private _parametros: Parametros[];
    private _servicos: Servicos[];
    private _retornamensagem: boolean;
    private _rps: number;
    
    constructor(props: any)
    {
        super(props);

        this._dadosobt=new RetornoAcesso();

        if (props.location.state)
        {
            if(props.location.state.dadosret)
            {
                this._dadosobt=props.location.state.dadosret;
            }
        }

        if (!this._dadosobt.ok)
        {
            this.props.history.push({pathname: '/'})
        }

        this._roteador=new Roteador(this._dadosobt, '/GeracaoRecebimentos');
        this._roteador.history=props.history;
        this._dadoobtido=false;
        this._redes = [];
        this._parametros = [];
        this._servicos = [];
        this._retornamensagem=false;
        this._rps = 0;
    
        this._acessos=0;
    
        if (this._dadosobt.ok)
        {
            this._dadoobtido=true;
        }
        this.state =
        {
            dadoobtido: this._dadoobtido,
            openToast: false,
            severidadeToast: 'success',
            mensagemToast: '',
            duracaoToast: 6000,
            acessosok: false,
        }
        if (this._dadosobt.ok)
        {
            this.ObtemDados('Redes/','redes',this.setaredes.bind(this));
            this.ObtemDados('Servicos/','serviços',this.setaservicos.bind(this));
            this.ObtemDados('Parametros/','parâmetros',this.setaparametros.bind(this));
            this.ObtemDados('Recebimentos/RPS','último rps',this.setarps.bind(this));
        }
    }
    
    setaredes(registros: any) {this._redes=registros; this.aumentaAcessos();};
    setaservicos(registros: any) {this._servicos=registros; this.aumentaAcessos();};
    setaparametros(parobt: Parametros[]) {this._parametros = parobt; this.aumentaAcessos();};
    setarps(rpsobt: {rcbt: number, dtrc: number})
    {
        this._rps=0;
        if(rpsobt.rcbt)
        {
            this._rps=rpsobt.rcbt;
        }
        if(rpsobt.dtrc && rpsobt.dtrc>this._rps)
        {
            this._rps=rpsobt.dtrc
        }
        this._rps=this._rps+1;
        this.aumentaAcessos();
    }

    aumentaAcessos()
    {
        this._acessos = this._acessos+1
        if(this._acessos>3)
        {
            AlteraTiposGeracao(this._parametros);
            AlteraPadroesRecebimento(this._parametros);
            this.setState({acessosok: true});
        }
    }
        
    ObtemDados(caminho: string, msgerr: string, obret: (registros: any) => void)
    {
        srv.Get(caminho, '', '', this._dadosobt.objac.token, false)
        .then((ddret: any) => 
        {
        let dadosret:Resposta = TrataResposta(ddret);
        if(dadosret.ok)
        {
            obret(dadosret.retorno)
        }
        else
        {
            this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro obtendo ' + msgerr + ' (' + dadosret.erro + ')' }); 
            this.aumentaAcessos();
        }
        })
        .catch(Error => {this.setState( {openToast: true, severidadeToast: 'error', duracaoToast: 6000, mensagemToast: 'Erro acessando a API - obtendo ' + msgerr + ' (' + Error.name + ' - ' + Error.message + ')' }); this.aumentaAcessos();});
    }

    ExibeMensagem(mensagem: string, severidade: string, duracao:number, retorna:boolean=false)
    {
        this.setState({openToast: true, severidadeToast: severidade, mensagemToast: mensagem, duracaoToast: duracao})
        this._retornamensagem=retorna;
    }

    FechaMensagem()
    {
        this.setState({openToast: false});
        if (this._retornamensagem)
        {
          this._roteador.rota(0,0);
        }
    }

    render()
    {
        if (!this._dadosobt.ok)
        {
        return null
        }
        else
        {
            return (
            <div className="Home">
                <header className="Home-header">
                <BarraLateral dadosacesso={this._dadosobt} botaoclick={this._roteador.rota.bind(this._roteador)} clasrot={this._roteador} recurso='Geração de Arquivo RPS -> NFS'/>
                </header>
                <main>
                { this.state.acessosok ?
                    <PrincipalGeracaoRPS dadosobt={this._dadosobt} redes={this._redes} servicos={this._servicos} ultrpsobt={this._rps} mensagem={this.ExibeMensagem.bind(this)}/>
                    :
                    <Progresso/>
                }
                </main>
                <Toast open = {this.state.openToast} handleClose = {this.FechaMensagem.bind(this)} severity = { this.state.severidadeToast} duracao= {this.state.duracaoToast}>{this.state.mensagemToast}</Toast>
            </div>
            )
        }
    }
}

export default withRouter(GeracaoRPS)
